import {
    Badge,
    Card,
    CardContent,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    makeStyles
} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import { ChevronRight, ExpandMore } from '@material-ui/icons';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { TreeItem, TreeView } from '@material-ui/lab';
import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'underscore';
import { unflatten } from '../../helpers/utility';
import { fetchUserReferralRequest } from '../../store/user/user.actions';
import { getUserReferrals } from '../../store/user/user.selector';
import TitleBar from '../base/TitleBar';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper
    }
}));

export default function ReferralContainer() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        dispatch(fetchUserReferralRequest());
    }, []);

    const { referals } = useSelector((state) => {
        return {
            referals: getUserReferrals(state)
        };
    }, []);

    const renderTree = (nodes) => {
        return (
            <TreeItem
                key={nodes._id}
                nodeId={nodes._id}
                label={
                    <Card elevation={10} className="my-2">
                        <CardContent className="py-0">
                            <List disablePadding>
                                <ListItem disableGutters className="py-0">
                                    <ListItemText
                                        primary={nodes.memberId}
                                        secondary={
                                            nodes.firstName +
                                            ' ' +
                                            nodes.lastName
                                        }
                                    />
                                </ListItem>
                                <ListItemSecondaryAction>
                                    <Badge
                                        badgeContent={
                                            nodes.children &&
                                            nodes.children.length
                                        }
                                        color="secondary"
                                    ></Badge>
                                </ListItemSecondaryAction>
                            </List>
                        </CardContent>
                    </Card>
                }
            >
                {Array.isArray(nodes.children)
                    ? nodes.children.map((node) => renderTree(node))
                    : null}
            </TreeItem>
        );
    };

    const data = unflatten(referals);

    let selectedRoots = referals.children.map((x) => x._id);
    const columns = [
        'Member #',
        'Name',
        'Mobile Number',
        'Plans',
        'Activated On'
    ];

    const datum = referals.children
        .filter((x) => x.depth === 0)
        .map((y) => {
            let value =
                referals &&
                referals.planMember &&
                referals.planMember.filter((t) => {
                    return t.memberId === y.memberId;
                });
            let latestDate = '';
            if (!value || value.length === 0) {
                latestDate = 'Not Activated';
            } else {
                if (value.length > 1) {
                    latestDate = _.sortBy(value, (t) =>
                        moment(t.createdAt).unix()
                    );
                    latestDate = latestDate[0].createdAt;
                    latestDate = moment(latestDate).format('LLL');
                } else {
                    latestDate = value[0].createdAt;
                    latestDate = moment(latestDate).format('LLL');
                }
            }
            let totalPlans =
                (referals.planMember &&
                    referals.planMember.filter((t) => {
                        return t.memberId === y.memberId;
                    }).length) ||
                0;
            return [
                y.memberId,
                y.firstName + ' ' + y.lastName,
                y.mobileNumber.replace(/\D/g, '').substr(0, 5) + 'XXXXX',
                totalPlans,
                latestDate
            ];
        });

    const options = {
        selectableRows: false,
        download: false,
        print: false,
        rowsPerPage: 100
    };

    return (
        <div>
            <TitleBar
                heading="Referrals"
                secondary={''}
                icon={
                    <HelpOutlineIcon fontSize="large" htmlColor="goldenrod" />
                }
            />
            <div className={classes.root}>
                <AppBar position="static">
                    <Tabs
                        value={value}
                        variant="fullWidth"
                        onChange={handleChange}
                        aria-label="simple tabs example"
                    >
                        <Tab
                            fullWidth
                            label="Referral List"
                            {...a11yProps(0)}
                        />
                        <Tab
                            fullWidth
                            label="Referral Tree"
                            {...a11yProps(1)}
                        />
                    </Tabs>
                </AppBar>
                <TabPanel value={value} index={0}>
                    <MUIDataTable
                        title={'Direct Member List'}
                        data={datum}
                        columns={columns}
                        options={options}
                    />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <TreeView
                        defaultCollapseIcon={<ExpandMore />}
                        defaultExpanded={selectedRoots}
                        defaultExpandIcon={<ChevronRight />}
                    >
                        {renderTree(data)}
                    </TreeView>
                </TabPanel>
            </div>
        </div>
    );
}
