import {
    Box,
    Button,
    FormControlLabel,
    InputAdornment,
    Radio,
    TextField
} from '@material-ui/core';
import { SwapVert } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { getWalletInformation } from '../../../store/user/user.selector';

export default function SellRequest(props) {
    const { onSell } = props;
    const [withdrawMode, setWithdrawMode] = useState('BNB');
    const [totalTokens, setTotalTokens] = useState(0);
    const [transactionCharge, setTransactionCharge] = useState(0);

    const { walletInfo } = useSelector((state) => {
        return {
            walletInfo: getWalletInformation(state)
        };
    }, []);

    let oneTokenPriceInUSD = parseFloat(walletInfo.tokenPrice.price);
    let oneUSDTokens = parseInt(1 / walletInfo.tokenPrice.price);
    let oneUSDBNB = parseInt(1 / walletInfo.coinPrice);
    let oneBNBPriceInUSD = parseFloat(walletInfo.coinPrice);
    let oneBNBTokens = parseInt(oneBNBPriceInUSD / walletInfo.tokenPrice.price);

    useEffect(() => {
        setTotalTokens(parseFloat(walletInfo.tokenBalance));
    }, [walletInfo, withdrawMode]);

    useEffect(() => {
        let charge = 0;
        if (withdrawMode === 'USDT') {
            charge = oneTokenPriceInUSD * totalTokens * 0.01;
            charge = (charge / parseFloat(walletInfo.coinPrice)).toFixed(8);
        } else {
            let transactionChargeUSDT = oneTokenPriceInUSD * totalTokens * 0.01;
            charge = (
                transactionChargeUSDT / parseFloat(walletInfo.coinPrice)
            ).toFixed(8);
            setTransactionCharge(charge);
        }

        setTransactionCharge(charge);
    }, [totalTokens, withdrawMode]);

    const handleSell = () => {
        let error = '';
        if (parseInt(Math.ceil(totalTokens * oneTokenPriceInUSD)) < 10) {
            error = 'Minimum Sell tokens are ' + parseInt(oneUSDTokens * 10);
        } else {
            if (parseFloat(walletInfo.tokenBalance) < parseFloat(totalTokens)) {
                error = "You don't have sufficient Tokens to Sell";
            }
            if (withdrawMode === 'USDT') {
                if (
                    parseFloat(walletInfo.coinBalance) <
                    parseFloat(transactionCharge)
                ) {
                    error = "You don't have sufficient BNB balance to process";
                }
            }
        }

        if (error) {
            Swal.fire({
                icon: 'error',
                title: 'Validation',
                text: error
            });
        } else {
            onSell(
                withdrawMode,
                withdrawMode === 'USDT'
                    ? parseFloat(totalTokens * oneTokenPriceInUSD)
                    : parseFloat((totalTokens / oneBNBTokens).toFixed(8)),
                parseFloat(Math.floor(totalTokens))
            );
        }
    };

    return (
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
            <TextField
                fullWidth
                margin="dense"
                className="my-1"
                variant="outlined"
                value={totalTokens}
                type="phone"
                onChange={(e) => {
                    let curValue = e.target.value;
                    if (!curValue) {
                        curValue = 0;
                    }
                    setTotalTokens(parseFloat(curValue));
                }}
                placeholder="Tokens"
                label="Tokens"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {walletInfo.tokenPrice.symbol}
                        </InputAdornment>
                    )
                }}
            />
            <TextField
                fullWidth
                margin="dense"
                className="my-1"
                variant="outlined"
                value={transactionCharge}
                type="phone"
                disabled
                placeholder="Tokens"
                label="Transaction Charge"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">BNB</InputAdornment>
                    )
                }}
            />
            <SwapVert fontSize="large" />
            <div>
                <FormControlLabel
                    value="start"
                    control={
                        <Radio
                            color="primary"
                            checked={withdrawMode === 'BNB'}
                        />
                    }
                    label={`Using BNB (${(totalTokens / oneBNBTokens).toFixed(
                        8
                    )})`}
                    labelPlacement="end"
                    onChange={() => {
                        setWithdrawMode(
                            withdrawMode === 'USDT' ? 'BNB' : 'USDT'
                        );
                    }}
                />
                <FormControlLabel
                    value="start"
                    control={
                        <Radio
                            color="primary"
                            checked={withdrawMode === 'USDT'}
                        />
                    }
                    label={`Using USDT (${(
                        totalTokens * oneTokenPriceInUSD
                    ).toFixed(3)})`}
                    labelPlacement="end"
                    onChange={() => {
                        setWithdrawMode(
                            withdrawMode === 'USDT' ? 'BNB' : 'USDT'
                        );
                    }}
                />
            </div>
            <Button
                className="mt-2"
                onClick={() => {
                    handleSell();
                }}
                variant="contained"
                color="primary"
                fullWidth
            >
                Sell Tokens
            </Button>
        </Box>
    );
}
