import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getToken, IsNullOrEmpty } from '../../helpers/utility';
import logo from '../../images/logo.png';
import SignUp from './SignUpPage';

export default function SignupContainer() {
    let token = getToken();
    const navigate = useNavigate();
    if (!IsNullOrEmpty(token)) {
        return navigate('/');
    }
    return (
        <div className="text-center">
            <div className="mt-4">
                <img src={logo} className="pt-2" width={220} />
                <SignUp />
            </div>
        </div>
    );
}
