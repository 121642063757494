import {
    Avatar,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    makeStyles
} from '@material-ui/core';
import {
    AccountBalanceWallet,
    AccountBalanceWalletRounded,
    CalendarToday,
    Close,
    FileCopy,
    Fingerprint,
    Money,
    Star,
    TransferWithinAStation,
    VerticalAlignTop,
    Visibility
} from '@material-ui/icons';
import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Toastr from 'toastr';
import _ from 'underscore';
import { fetchSellRequest } from '../../store/transaction/transaction.actions';
import { getSellRequests } from '../../store/transaction/transaction.selector';
import TitleBar from '../base/TitleBar';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper
    }
}));

export default function SellContainer() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [selectedItem, setSelecteItem] = useState({});
    const [open, setOpen] = useState(false);

    useEffect(() => {
        dispatch(fetchSellRequest('Approved'));
    }, []);

    const { buys } = useSelector((state) => {
        return {
            buys: getSellRequests(state)
        };
    }, []);

    const actionRenderer = () => {
        return {
            name: 'id',
            label: 'Action',
            options: {
                filter: false,
                customBodyRender: (value) => {
                    let dep = _.find(buys, (x) => x._id === value);
                    return (
                        <Visibility
                            className={classes.icon}
                            color="secondary"
                            onClick={() => {
                                setOpen(true);
                                setSelecteItem(dep);
                            }}
                        />
                    );
                }
            }
        };
    };

    const columns = [
        {
            name: 'createdAt',
            label: 'Transaction Time',
            options: {
                filter: false,
                sort: true
            }
        },
        {
            name: 'coins',
            label: 'Coins',
            options: {
                filter: false,
                sort: true
            }
        },
        {
            name: 'tokens',
            label: 'Tokens',
            options: {
                filter: false,
                sort: true
            }
        },

        {
            name: 'transactionCharge',
            label: 'Fees',
            options: {
                filter: false,
                sort: true
            }
        },
        {
            name: 'status',
            label: 'Status',
            options: {
                filter: true,
                sort: true
            }
        },
        actionRenderer()
    ];

    const datum = buys.map((item) => {
        let transactionCharge = item.transactionCharge;
        return {
            id: item._id,
            createdAt: moment(item.createdAt).format('lll'),
            coins:
                item.transactionCoins +
                ' ' +
                (item.transactionMode === 'USDT'
                    ? item.transactionMode
                    : item.coinType),
            tokens: item.transactionTokens + ' ' + item.tokenSymbol,
            transactionCharge: transactionCharge + ' ' + item.coinType,
            spentHash: item.spentHash,
            receiveHash: item.receiveHash,
            status: item.status
        };
    });

    const options = {
        selectableRows: false,
        download: false,
        print: false
    };

    return (
        <div className="mb-4 pb-4">
            <Dialog
                maxWidth="sm"
                fullWidth
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
            >
                <DialogTitle>
                    Buy Transaction{' '}
                    <Close
                        className="mt-1 float-right"
                        onClick={() => setOpen(false)}
                    />
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <List>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar className="bg-success">
                                    <Fingerprint />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={selectedItem.referenceNo}
                                secondary={'Reference No'}
                            />
                            <ListItemSecondaryAction>
                                <FileCopy
                                    color="secondary"
                                    onClick={() => {
                                        navigator.clipboard.writeText(
                                            selectedItem.referenceNo
                                        );
                                        Toastr.info('copied to clipboard');
                                    }}
                                />
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar className="bg-success">
                                    <TransferWithinAStation />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={selectedItem.transactionId}
                                secondary={'Transaction ID'}
                            />
                            <ListItemSecondaryAction>
                                <FileCopy
                                    color="secondary"
                                    onClick={() => {
                                        navigator.clipboard.writeText(
                                            selectedItem.transactionId
                                        );
                                        Toastr.info('copied to clipboard');
                                    }}
                                />
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar className="bg-success">
                                    <Money />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    selectedItem.transactionCoins +
                                    ' ' +
                                    (selectedItem.transactionMode === 'USDT'
                                        ? selectedItem.transactionMode
                                        : selectedItem.coinType)
                                }
                                secondary={'Spent Amount'}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar className="bg-success">
                                    <AccountBalanceWallet />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    selectedItem.transactionTokens +
                                    ' ' +
                                    selectedItem.tokenSymbol
                                }
                                secondary={'Received Tokens'}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar className="bg-success">
                                    <AccountBalanceWalletRounded />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    selectedItem.transactionCharge +
                                    ' ' +
                                    selectedItem.coinType
                                }
                                secondary={'Transaction Charge'}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar className="bg-success">
                                    <CalendarToday />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={moment(selectedItem.createdAt).format(
                                    'lll'
                                )}
                                secondary={'Transaction Date'}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar className="bg-success">
                                    <Star />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={selectedItem.status}
                                secondary={'Status'}
                            />
                        </ListItem>
                    </List>
                </DialogContent>
            </Dialog>
            <TitleBar
                heading="Buy Transactions"
                secondary={''}
                icon={
                    <VerticalAlignTop fontSize="large" htmlColor="goldenrod" />
                }
            />
            <div className={classes.root}>
                <MUIDataTable
                    title={'Buy Transactions List'}
                    data={datum}
                    columns={columns}
                    options={options}
                />
            </div>
        </div>
    );
}
