import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Container,
    TextField
} from '@material-ui/core';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { validEmail } from '../../helpers/utility';
import logo from '../../images/logo.png';
import UserAPI from '../../store/user/user.api';

export default function ResetPassword() {
    const [email, setEmail] = useState('');

    return (
        <Container maxWidth="xs" fullWidth>
            <p className="text-center my-4">
                <img src={logo} height={128} />
            </p>
            <Card>
                <CardHeader
                    title={'Reset Password'}
                    subheader="Reset using email"
                />
                <CardContent>
                    <TextField
                        type="email"
                        placeholder="Enter Your Email Address"
                        onChange={(e) => setEmail(e.target.value)}
                        variant="outlined"
                        fullWidth
                    />
                </CardContent>
                <CardActions>
                    <Button
                        color="primary"
                        variant="contained"
                        disabled={!validEmail(email)}
                        fullWidth
                        onClick={() => {
                            if (validEmail(email)) {
                                UserAPI.ForgotPassword(email).then((s) => {
                                    Swal.fire({
                                        title: 'Temporary Password',
                                        text: 'Temporary Password sent to your registered email',
                                        icon: 'info'
                                    }).then((s) => {
                                        if (s.isConfirmed) {
                                            document.location.href = '/login';
                                        }
                                    });
                                });
                            }
                        }}
                        className="btn btn-primary"
                    >
                        Reset
                    </Button>
                </CardActions>
            </Card>
            <p className="text-center my-2">
                Remember Password?
                <Link to="/login"> Sign in here</Link>
            </p>
        </Container>
    );
}
