import { action } from 'typesafe-actions';
import { AuthenticationActionTypes } from './auth.model';

export const loginUserRequest = (req) =>
    action(AuthenticationActionTypes.LOGIN_REQUEST, req);
export const loginUserSuccess = (res) =>
    action(AuthenticationActionTypes.LOGIN_SUCCESS, res);
export const loginUserFailure = (err) =>
    action(AuthenticationActionTypes.LOGIN_FAILURE, err);

export const logoutUserRequest = (req) =>
    action(AuthenticationActionTypes.LOGOUT_REQUEST, req);
export const logoutUserSuccess = (res) =>
    action(AuthenticationActionTypes.LOGOUT_FAILURE, res);
export const logoutUserFailure = (err) =>
    action(AuthenticationActionTypes.LOGOUT_SUCCESS, err);
