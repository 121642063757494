import {
    Avatar,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    ListItem,
    ListItemAvatar,
    ListItemText,
    makeStyles
} from '@material-ui/core';
import {
    CalendarToday,
    Close,
    Code,
    Fingerprint,
    FlipToBackRounded,
    FormatAlignCenter,
    Money,
    OpenInNew,
    Star,
    TransferWithinAStation,
    VerticalAlignBottom,
    Visibility
} from '@material-ui/icons';
import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'underscore';
import { getTransactionUrl } from '../../helpers/utility';
import { fetchPayoutRequest } from '../../store/transaction/transaction.actions';
import { getPayoutRequests } from '../../store/transaction/transaction.selector';
import TitleBar from '../base/TitleBar';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper
    }
}));

export default function PayoutContainer() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [selectedItem, setSelecteItem] = useState({});
    const [open, setOpen] = useState(false);

    const { transactions } = useSelector((state) => {
        return {
            transactions: getPayoutRequests(state)
        };
    });

    useEffect(() => {
        dispatch(fetchPayoutRequest());
    }, []);

    const actionRenderer = () => {
        return {
            name: 'id',
            label: 'Action',
            options: {
                filter: false,
                customBodyRender: (value) => {
                    let dep = _.find(transactions, (x) => x._id === value);
                    return (
                        <Visibility
                            className={classes.icon}
                            color="secondary"
                            onClick={() => {
                                setOpen(true);
                                setSelecteItem(dep);
                            }}
                        />
                    );
                }
            }
        };
    };

    const columns = [
        {
            name: 'type',
            label: 'Type',
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: 'createdAt',
            label: 'Created Time',
            options: {
                filter: false,
                sort: true
            }
        },
        {
            name: 'createdBy',
            label: 'From',
            options: {
                filter: false,
                sort: true
            }
        },
        {
            name: 'coins',
            label: 'Rewards',
            options: {
                filter: false,
                sort: true
            }
        },
        {
            name: 'transactionHash',
            label: 'Hash',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => {
                    return (
                        value && (
                            <a
                                target="_blank"
                                href={getTransactionUrl('BNB') + value}
                            >
                                <OpenInNew color="primary" />
                            </a>
                        )
                    );
                }
            }
        },
        {
            name: 'status',
            label: 'Status',
            options: {
                filter: true,
                sort: true
            }
        },
        actionRenderer()
    ];

    const datum = transactions.map((item) => {
        return {
            id: item._id,
            updatedAt:
                (item.planMembers &&
                    item.planMembers[0] &&
                    moment(item.planMembers[0].createdAt).format('lll')) ||
                moment(item.createdAt).format('lll'),
            createdAt: moment(item.createdAt).format('lll'),
            coins: '$' + item.amount,
            status: item.status,
            createdBy: item.createdBy,
            type: item.type,
            transactionHash: item.transactionHash
        };
    });

    const options = {
        selectableRows: false,
        download: false,
        print: false
    };

    const selectedPlan =
        selectedItem && selectedItem.planMembers && selectedItem.planMembers[0];
    let currency = 'USD';
    return (
        <div className="mb-4 pb-4">
            {selectedPlan && selectedPlan._id && (
                <Dialog
                    maxWidth="md"
                    fullWidth
                    open={open}
                    onClose={() => {
                        setOpen(false);
                    }}
                >
                    <DialogTitle>
                        Payout Information{' '}
                        <Close
                            className="mt-1 float-right"
                            onClick={() => setOpen(false)}
                        />
                    </DialogTitle>
                    <Divider />
                    <DialogContent>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar className="bg-success">
                                            <Fingerprint />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={selectedItem._id}
                                        secondary={'Reference No'}
                                    />
                                </ListItem>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar className="bg-success">
                                            <TransferWithinAStation />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={selectedItem.type}
                                        secondary={'Type'}
                                    />
                                </ListItem>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar className="bg-success">
                                            <Money />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={selectedItem.amount + ' USD'}
                                        secondary={'Amount'}
                                    />
                                </ListItem>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar className="bg-success">
                                            <CalendarToday />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={moment(
                                            selectedItem.createdAt
                                        ).format('lll')}
                                        secondary={'Transaction Date'}
                                    />
                                </ListItem>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar className="bg-success">
                                            <FormatAlignCenter />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={selectedItem.createdBy}
                                        secondary={'From'}
                                    />
                                </ListItem>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar className="bg-success">
                                            <FlipToBackRounded />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={selectedItem.memberId}
                                        secondary={'To'}
                                    />
                                </ListItem>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar className="bg-success">
                                            <Code />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={selectedItem.method}
                                        secondary={'Method'}
                                    />
                                </ListItem>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar className="bg-success">
                                            <Code />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            selectedItem.reinvestment + ' USD'
                                        }
                                        secondary={'Reinvestment'}
                                    />
                                </ListItem>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar className="bg-success">
                                            <Code />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            selectedItem.liquidity + ' USD'
                                        }
                                        secondary={'Liquidity'}
                                    />
                                </ListItem>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar className="bg-success">
                                            <Code />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            selectedItem.adminFees + ' USD'
                                        }
                                        secondary={'Gas Fees'}
                                    />
                                </ListItem>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar className="bg-success">
                                            <Star />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={selectedItem.status}
                                        secondary={'Status'}
                                    />
                                </ListItem>
                            </Grid>
                        </Grid>
                        <ul className="stripe-ul">
                            <li>
                                <span>Joined Date :</span>
                                <span>
                                    {moment(selectedPlan.createdAt).format(
                                        'll'
                                    )}
                                </span>
                            </li>
                            <li>
                                <span>Maturity Date :</span>
                                <span>
                                    {moment(selectedPlan.maturityDate).format(
                                        'll'
                                    )}
                                </span>
                            </li>
                            <li>
                                <span>Duration : </span>
                                <span>
                                    {selectedPlan.returns &&
                                        selectedPlan.returns.length}{' '}
                                    Months
                                </span>
                            </li>
                            <li>
                                <span>Amount : </span>
                                <span>{selectedPlan.amount} USD</span>
                            </li>
                        </ul>
                        <table className="table table-striped mt-2">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Date</th>
                                    <th>Amount</th>
                                    <th>Reward</th>
                                    <th>Net Reward</th>
                                    <th>Reinvesment</th>
                                    <th>Liquidity</th>
                                    <th>Gas Fees</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedPlan.returns &&
                                    selectedPlan.returns.map((s, index) => {
                                        return (
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td>{s.date}</td>
                                                <td>
                                                    {s.amount} {currency}
                                                </td>
                                                <td>
                                                    {s.interest} {currency}
                                                </td>
                                                <td>
                                                    {s.withdrawalAmount}{' '}
                                                    {currency}
                                                </td>
                                                <td>
                                                    {s.reinvest} {currency}
                                                </td>
                                                <td>
                                                    {s.liquidity} {currency}
                                                </td>
                                                <td>
                                                    {s.adminFees} {currency}
                                                </td>
                                                <td>{s.status}</td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table>
                    </DialogContent>
                    <DialogActions>
                        <Box
                            width={'100%'}
                            display={'flex'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                        >
                            {selectedItem && selectedItem.transactionHash && (
                                <a
                                    target="_blank"
                                    href={
                                        getTransactionUrl('BNB') +
                                        selectedItem.transactionHash
                                    }
                                >
                                    Transaction Hash{' '}
                                    <OpenInNew color="primary" />
                                </a>
                            )}
                        </Box>
                    </DialogActions>
                </Dialog>
            )}
            <TitleBar
                heading="Payout Transactions"
                secondary={''}
                icon={
                    <VerticalAlignBottom
                        fontSize="large"
                        htmlColor="goldenrod"
                    />
                }
            />
            <div className={classes.root}>
                <MUIDataTable
                    title={'Payout List'}
                    data={datum}
                    columns={columns}
                    options={options}
                />
            </div>
        </div>
    );
}
