import { Box, InputAdornment, TextField } from '@material-ui/core';
import { FileCopy } from '@material-ui/icons';
import React from 'react';
import ReactQRCode from 'react-qr-code';
import { useSelector } from 'react-redux';
import * as Toastr from 'toastr';
import { getCurrentUser } from '../../../store/user/user.selector';

export default function DepositRequest(props) {
    const { currentUser } = useSelector((state) => {
        return {
            currentUser: getCurrentUser(state)
        };
    }, []);

    return (
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
            <ReactQRCode value={currentUser.walletAddress} />
            <p className="my-2">
                Scan the QR Code from your app and deposit BNB / USDT
            </p>
            <TextField
                disabled
                fullWidth
                variant="outlined"
                margin="dense"
                value={currentUser.walletAddress}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <FileCopy
                                color="primary"
                                onClick={() => {
                                    navigator.clipboard.writeText(
                                        currentUser.walletAddress
                                    );
                                    Toastr.info('Address copied to clipboard');
                                }}
                            />
                        </InputAdornment>
                    )
                }}
            />
        </Box>
    );
}
