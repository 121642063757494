import { Card, CardHeader, Grid, makeStyles } from '@material-ui/core';
import {
    AccountBalance,
    AccountTree,
    ArrowDownward,
    ArrowUpward,
    CloudDownload,
    DateRange,
    Event,
    Group,
    MonetizationOn,
    PersonAdd,
    PersonAddDisabled,
    RedoTwoTone,
    Report
} from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    downloadActiveIDReport,
    downloadInActiveIDReport,
    downloadMonthlyReTopupReport,
    downloadPayoutReport,
    downloadReTopupReport,
    downloadRefReport,
    downloadReferralReport,
    downloadRewardReport,
    downloadSwapReport,
    downloadTeamReTopupReport,
    downloadWithdrawReport
} from '../../helpers/PDFGenerator';
import PlanAPI from '../../store/plan/plan.api';
import { fetchPayoutRequest } from '../../store/transaction/transaction.actions';
import TransactionAPI from '../../store/transaction/transaction.api';
import { getPayoutRequests } from '../../store/transaction/transaction.selector';
import { fetchUserReferralRequest } from '../../store/user/user.actions';
import { getUserReferrals } from '../../store/user/user.selector';
import Loader from '../base/loader';
import TitleBar from '../base/TitleBar';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper
    }
}));

export default function ReportContainer() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const classes = useStyles();

    const { referals, payouts } = useSelector((state) => {
        return {
            referals: getUserReferrals(state),
            payouts: getPayoutRequests(state)
        };
    }, []);

    useEffect(() => {
        if (!referals || !referals.children || referals.children.length === 0) {
            dispatch(fetchUserReferralRequest());
        }
        if (payouts.length === 0) {
            dispatch(fetchPayoutRequest());
        }
    }, []);

    return (
        <div className="mb-4 pb-4">
            {loading && <Loader />}
            <TitleBar
                heading="Reports"
                secondary={''}
                icon={<Report fontSize="large" htmlColor="goldenrod" />}
            />
            <div className={classes.root}>
                <Grid container>
                    <Grid item xs={12} sm={12} md={4} lg={4} className="p-3">
                        <Card
                            className="cursor"
                            elevation={10}
                            onClick={() => {
                                downloadActiveIDReport(referals);
                            }}
                        >
                            <CardHeader
                                avatar={<PersonAdd color="secondary" />}
                                title={'Active ID Report'}
                                subheader="All Levels"
                                action={
                                    <CloudDownload
                                        className="mt-3"
                                        color="primary"
                                    />
                                }
                            />
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} className="p-3">
                        <Card
                            className="cursor"
                            elevation={10}
                            onClick={() => downloadInActiveIDReport(referals)}
                        >
                            <CardHeader
                                avatar={<PersonAddDisabled color="secondary" />}
                                title={'In-active ID Report'}
                                subheader="All Levels"
                                action={
                                    <CloudDownload
                                        className="mt-3"
                                        color="primary"
                                    />
                                }
                            />
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} className="p-3">
                        <Card
                            className="cursor"
                            elevation={10}
                            onClick={() => downloadReferralReport(referals)}
                        >
                            <CardHeader
                                avatar={<PersonAdd color="secondary" />}
                                title={'Referral Report'}
                                subheader="All Levels"
                                action={
                                    <CloudDownload
                                        className="mt-3"
                                        color="primary"
                                    />
                                }
                            />
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} className="p-3">
                        <Card
                            className="cursor"
                            elevation={10}
                            onClick={() => {
                                downloadPayoutReport(payouts);
                            }}
                        >
                            <CardHeader
                                avatar={<MonetizationOn color="secondary" />}
                                title={'Payout Report'}
                                subheader="All Plans"
                                action={
                                    <CloudDownload
                                        className="mt-3"
                                        color="primary"
                                    />
                                }
                            />
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} className="p-3">
                        <Card
                            className="cursor"
                            elevation={10}
                            onClick={() => {
                                downloadRewardReport(payouts);
                            }}
                        >
                            <CardHeader
                                avatar={<DateRange color="secondary" />}
                                title={'Rewards Report'}
                                subheader="All Levels"
                                action={
                                    <CloudDownload
                                        className="mt-3"
                                        color="primary"
                                    />
                                }
                            />
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4} className="p-3">
                        <Card
                            className="cursor"
                            elevation={10}
                            onClick={() => {
                                downloadRefReport(payouts);
                            }}
                        >
                            <CardHeader
                                avatar={<AccountTree color="secondary" />}
                                title={'Referal commission'}
                                subheader="All Levels"
                                action={
                                    <CloudDownload
                                        className="mt-3"
                                        color="primary"
                                    />
                                }
                            />
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} className="p-3">
                        <Card
                            className="cursor"
                            elevation={10}
                            onClick={() => {
                                TransactionAPI.GetWithdrawTransactions(
                                    'Approved'
                                ).then((s) => {
                                    downloadWithdrawReport(s);
                                });
                            }}
                        >
                            <CardHeader
                                avatar={<AccountBalance color="secondary" />}
                                title={'Withdraw Report'}
                                subheader="All Withdraw Transactions"
                                action={
                                    <CloudDownload
                                        className="mt-3"
                                        color="primary"
                                    />
                                }
                            />
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4} className="p-3">
                        <Card
                            className="cursor"
                            elevation={10}
                            onClick={() => {
                                TransactionAPI.GetBuyTransactions(
                                    'Approved'
                                ).then((s) => {
                                    downloadSwapReport(
                                        s,
                                        'Buy Transaction Report'
                                    );
                                });
                            }}
                        >
                            <CardHeader
                                avatar={<ArrowUpward color="secondary" />}
                                title={'Buy Report'}
                                subheader="All Buy Transactions"
                                action={
                                    <CloudDownload
                                        className="mt-3"
                                        color="primary"
                                    />
                                }
                            />
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} className="p-3">
                        <Card
                            className="cursor"
                            elevation={10}
                            onClick={() => {
                                TransactionAPI.GetSellTransactions(
                                    'Approved'
                                ).then((s) => {
                                    downloadSwapReport(
                                        s,
                                        'Sell Transaction Report'
                                    );
                                });
                            }}
                        >
                            <CardHeader
                                avatar={<ArrowDownward color="secondary" />}
                                title={'Sell Report'}
                                subheader="All Sell Transactions"
                                action={
                                    <CloudDownload
                                        className="mt-3"
                                        color="primary"
                                    />
                                }
                            />
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4} className="p-3">
                        <Card
                            className="cursor"
                            elevation={10}
                            onClick={() => {
                                PlanAPI.GetPlanMember().then((s) => {
                                    downloadMonthlyReTopupReport(
                                        s,
                                        'Monthly Retopup Report'
                                    );
                                });
                            }}
                        >
                            <CardHeader
                                avatar={<Event color="secondary" />}
                                title={'Monthly Retopup Report'}
                                subheader="All Levels"
                                action={
                                    <CloudDownload
                                        className="mt-3"
                                        color="primary"
                                    />
                                }
                            />
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} className="p-3">
                        <Card
                            className="cursor"
                            elevation={10}
                            onClick={() => {
                                PlanAPI.GetPlanMember().then((s) => {
                                    downloadReTopupReport(
                                        s,
                                        'Retopup Commission Report'
                                    );
                                });
                            }}
                        >
                            <CardHeader
                                avatar={<RedoTwoTone color="secondary" />}
                                title={'Retopup commission'}
                                subheader="All Levels"
                                action={
                                    <CloudDownload
                                        className="mt-3"
                                        color="primary"
                                    />
                                }
                            />
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} className="p-3">
                        <Card
                            className="cursor"
                            elevation={10}
                            onClick={() => {
                                PlanAPI.GetTeamPlanMember().then((s) => {
                                    downloadTeamReTopupReport(
                                        s,
                                        'Team Retopup Commission Report'
                                    );
                                });
                            }}
                        >
                            <CardHeader
                                avatar={<Group color="secondary" />}
                                title={'Team Retopup commission'}
                                subheader="All Levels"
                                action={
                                    <CloudDownload
                                        className="mt-3"
                                        color="primary"
                                    />
                                }
                            />
                        </Card>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}
