import { call, put, takeEvery } from 'redux-saga/effects';
import * as UserActions from './user.actions';
import UserAPI from './user.api';
import { UserActionTypes } from './user.model';
import Swal from 'sweetalert2';

export function* handleRegisterUser(action) {
    try {
        const response = yield call(UserAPI.RegisterUser, action.payload);
        yield put(UserActions.registerUserSuccess(response));
        Swal.fire({
            title: 'Registration successful',
            text:
                'Your Member ID is ' +
                response.memberId +
                '.Please login to your account now',
            icon: 'success',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            showConfirmButton: false,
            cancelButtonText: 'Close',
            customClass: {
                cancelButton: 'order-1 right-gap',
                confirmButton: 'order-2',
                denyButton: 'order-3'
            }
        }).then((x) => {
            if (x.isDismissed) {
                document.location.href = '/login';
            }
        });
    } catch (e) {
        yield put(UserActions.registerUserFailure(e));
    }
}

export function* handleCurrentUser() {
    try {
        const response = yield call(UserAPI.GetCurrentUser);
        yield put(UserActions.fetchUserSuccess(response));
    } catch (e) {
        yield put(UserActions.fetchUserFailure(e));
    }
}

export function* handleUpdateUser(action) {
    try {
        const response = yield call(UserAPI.UpdateCurrentUser, action.payload);
        yield put(UserActions.updateUserSuccess(response));
    } catch (e) {
        yield put(UserActions.updateUserFailure(e));
    }
}

export function* handleUserReferrals() {
    try {
        const response = yield call(UserAPI.GetCurrentUserReferrals);
        yield put(UserActions.fetchUserReferralSuccess(response));
    } catch (e) {
        yield put(UserActions.fetchUserReferralSuccess(e));
    }
}

export function* handleUserReferees() {
    try {
        const response = yield call(UserAPI.GetCurrentUserReferees);
        yield put(UserActions.fetchUserRefereeSuccess(response));
    } catch (e) {
        yield put(UserActions.fetchUserRefereeFailure(e));
    }
}

export function* handleUsers() {
    try {
        const response = yield call(UserAPI.GetUsers);
        yield put(UserActions.fetchUsersSuccess(response));
    } catch (e) {
        yield put(UserActions.fetchUsersFailure(e));
    }
}

export function* handleFetchWallet() {
    try {
        const response = yield call(UserAPI.GetWalletInfo);
        yield put(UserActions.fetchUserWalletSuccess(response));
    } catch (e) {
        yield put(UserActions.fetchUserWalletFailure(e));
    }
}

export function* handleWithdrawWallet(action) {
    try {
        const response = yield call(UserAPI.CreateWhitelist, action.payload);
        yield put(UserActions.withdrawWalletSuccess(response));
        yield put(UserActions.fetchUserRequest());
    } catch (e) {
        yield put(UserActions.withdrawWalletFailure(e));
    }
}

export function* handleChange2FA(action) {
    try {
        const response = yield call(UserAPI.ChangeTo2FA, action.payload);
        yield put(UserActions.change2FASuccess(response));
        Swal.fire({
            title: '2FA Setup',
            text: '2FA Security changed to Google Authenticator Now',
            icon: 'success'
        });
    } catch (e) {
        yield put(UserActions.change2FAFailure(e));
    }
}

export function* handleChangePin(action) {
    try {
        const response = yield call(UserAPI.ChangeToPIN, action.payload);
        yield put(UserActions.changePinSuccess(response));
        Swal.fire({
            title: '2FA Setup',
            text: '2FA Security changed to User Pin Now',
            icon: 'success'
        });
    } catch (e) {
        yield put(UserActions.changePinFailure(e));
    }
}

export function* handleChangeNominee(action) {
    try {
        const response = yield call(UserAPI.ChangeNominee, action.payload);
        yield put(UserActions.updateNomineeSuccess(response));
        Swal.fire({
            title: 'Nominee Update',
            text: 'Nominee updated Successfully',
            icon: 'success'
        });
        yield put(UserActions.fetchUserRequest());
    } catch (e) {
        yield put(UserActions.updateNomineeFailure(e));
    }
}

export function* UserSaga() {
    yield takeEvery(UserActionTypes.REGISTER_USER_REQUEST, handleRegisterUser);
    yield takeEvery(UserActionTypes.FETCH_USER_REQUEST, handleCurrentUser);
    yield takeEvery(UserActionTypes.UPDATE_USER_REQUEST, handleUpdateUser);
    yield takeEvery(UserActionTypes.CHANGE_2FA_REQUEST, handleChange2FA);
    yield takeEvery(UserActionTypes.CHANGE_PIN_REQUEST, handleChangePin);
    yield takeEvery(UserActionTypes.FETCH_REFERAL_REQUEST, handleUserReferrals);
    yield takeEvery(UserActionTypes.FETCH_REFEREE_REQUEST, handleUserReferees);
    yield takeEvery(UserActionTypes.FETCH_USERS_REQUEST, handleUsers);
    yield takeEvery(UserActionTypes.FETCH_WALLET_REQUEST, handleFetchWallet);
    yield takeEvery(
        UserActionTypes.WITHDRAW_WALLET_REQUEST,
        handleWithdrawWallet
    );
    yield takeEvery(
        UserActionTypes.UPDATE_NOMINEE_REQUEST,
        handleChangeNominee
    );
}
