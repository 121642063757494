import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    typography: {
        fontFamily: ['"Nunito Sans"', 'sans-serif'].join(','),
        fontWeightBold: 700,
        fontWeightLight: 400,
        fontWeightMedium: 600,
        fontWeightRegular: 500
    },
    overrides: {
        MuiTab: {
            root: {
                textTransform: 'none'
            }
        },
        MuiButton: {
            root: {
                textTransform: 'none'
            }
        }
    },
    palette: {
        type: 'dark',
        primary: {
            main: '#FBC707'
        },
        secondary: {
            main: '#000000'
        }
    }
});

export default theme;
