import React from 'react';
import logo from '../../images/logo.png';
import SignIn from './LoginPage';

export default function LoginContainer() {
    return (
        <div className="text-center">
            <div className="mt-4">
                <img src={logo} className="pt-2" width={220} />
                <SignIn />
            </div>
        </div>
    );
}
