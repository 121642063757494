import {
    Avatar,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    InputAdornment,
    ListItem,
    ListItemAvatar,
    ListItemText,
    TextField,
    makeStyles
} from '@material-ui/core';
import { deepOrange } from '@material-ui/core/colors';
import {
    AccountBalance,
    AccountBalanceWallet,
    Close,
    ListAlt
} from '@material-ui/icons';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { decodeToken } from '../../helpers/utility';
import { loginUserSuccess } from '../../store/authentication/auth.actions';
import {
    createPlanMemberRequest,
    fetchPlanMemberRequest,
    fetchPlanRequest,
    fetchPlansRequest
} from '../../store/plan/plan.actions';
import {
    getPlanDetail,
    getPlanMembers,
    getPlans
} from '../../store/plan/plan.selector';
import UserAPI from '../../store/user/user.api';
import {
    getCurrentUser,
    getWalletInformation
} from '../../store/user/user.selector';
import Loader from '../base/loader';
import TitleBar from '../base/TitleBar';
import VerifyPin from '../verify/verifyPin';
import MyPlanDetail from './MyPlan.Detail';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    }
}));

export default function MyPlanContainer() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [currency, setCurrency] = useState('USD');
    const [dollar, setDollar] = useState(1);
    const [amount, setAmount] = useState(1);
    const [acknowledge, setAcknowledge] = useState(false);
    const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
    const [loading, setLoading] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState('');
    const user = decodeToken();

    useEffect(() => {
        dispatch(fetchPlanMemberRequest());
        dispatch(fetchPlansRequest());
    }, []);

    const {
        plans,
        planDetail,
        planMembers,
        walletInfo,
        currentUser,
        isLoading
    } = useSelector((state) => {
        return {
            plans: getPlans(state),
            planDetail: getPlanDetail(state),
            planMembers: getPlanMembers(state),
            walletInfo: getWalletInformation(state),
            currentUser: getCurrentUser(state),
            isLoading: state.PlanReducer.isLoading
        };
    });

    useEffect(() => {
        setOpen(false);
        setAmount(1);
        if (
            walletInfo &&
            walletInfo.tokenPrice &&
            walletInfo.tokenPrice.price
        ) {
            setDollar(parseInt(amount / walletInfo.tokenPrice.price));
        }
        setSelectedPlan('');
    }, [planMembers]);

    let intAmount =
        planDetail &&
        planDetail
            .map((t) => parseFloat(t.withdrawalAmount))
            .reduce((s, f) => s + f, 0);
    if (intAmount) {
        intAmount = parseFloat(intAmount).toFixed(3);
    }

    if (
        walletInfo &&
        walletInfo.coinBalance &&
        parseFloat(walletInfo.coinBalance) < 0.002 &&
        planMembers.length === 0
    ) {
        return (
            <Dialog maxWidth="sm" open={true}>
                <DialogTitle>
                    InSufficient Balance
                    <Close
                        onClick={() => navigate('/profile')}
                        className="float-right"
                    />
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <h4 className="text-danger text-center">
                        Please have atleast 0.002 BNB in your wallet to create a
                        plan
                    </h4>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => navigate('/profile')}
                    >
                        Proceed
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    return (
        <div className="mb-2 pb-2">
            {(isLoading || loading) && <Loader />}
            <Dialog maxWidth="md" open={!user.isTokenApproved}>
                <DialogTitle>Terms and Conditions</DialogTitle>
                <Divider />
                <DialogContent>
                    <p>
                        Terms used in this Agreement with their initial letters
                        capitalised have the meanings ascribed to them in this
                        section or where they are elsewhere defined in this
                        Agreement. Any term defined in the singular will have
                        the corresponding definition in the plural (and vice
                        versa). As used in this Agreement:
                    </p>
                    <p>
                        “Agreement” means these Terms and Conditions and all
                        attachments, including any Order Forms signed by the
                        Parties.
                    </p>
                    <p>
                        “Client” means the Party using the Service provided by
                        Taurus.
                    </p>
                    <p>
                        “Confidential Information” means all non-public
                        information disclosed by one party to the other in
                        connection with this Agreement that the disclosing party
                        marks as confidential or which the receiving party
                        should reasonably know to be the confidential
                        information of the other party.
                    </p>
                    <p>
                        “Digital Assets” means digital representations of
                        securities, rights, financial instruments and/or units
                        of accounts booked on a distributed ledger/blockchain.
                        For the avoidance of doubt, Digital Assets include
                        payment tokens, utility tokens and asset/security tokens
                        as defined by FINMA.
                    </p>
                    <p>
                        “FINMA” means the Swiss Financial Market Supervisory
                        Authority.
                    </p>
                    <p>
                        “Order Form” means the service order form signed by the
                        Parties.
                    </p>
                    <p>
                        “Party” means the Client or Taurus, as applicable, and
                        “Parties” means the Client and Taurus collectively.
                    </p>
                    <p>“PoS” means Proof-of-Stake.</p>
                    <p>
                        “Proof-of-Stake” means a distributed ledger/blockchain
                        consensus algorithm where Digital Assets are “staked” to
                        a Validator to validate transactions. This definition
                        includes any types of proof-of-stake algorithms
                        including delegated proof-of-stake algorithms (DPoS).
                    </p>
                    <p>
                        “Service” means Taurus’ provision of proof-of-stake
                        validation IT services to Supported Networks, including,
                        without limitation, producing and validating new blocks,
                        processing transactions, and securing the network.
                    </p>
                    <p>
                        “Slashing Penalties” means any penalty assessed by the
                        Supported Network for Taurus’ failure to perform the
                        Service according to the Supported Network Protocol.
                    </p>
                    <p>
                        “Staking” means use of the Service by the Client to gain
                        Staking Rewards.
                    </p>
                    <p>
                        “Staked Digital Assets” means the Digital Assets that
                        the Client has Staked with the Service.
                    </p>
                    <p>
                        “Staking Rewards” means digital assets distributed by
                        the Supported Network in compensation for “staking” less
                        Taurus fees and any Slashing Penalties.
                    </p>
                    <p>
                        “Supported Network” means any distributed
                        ledger/blockchain network for which Taurus is able to
                        perform the Service.
                    </p>
                    <p>
                        “Supported Network Protocol” means the rules by which
                        the Supported Network operates including but not limited
                        to the process for Staking, the quantity and timing of
                        Staking Rewards, and the conditions under which Slashing
                        Penalties are imposed.
                    </p>
                    <p>
                        “Validator” means a validator node, a baker or
                        equivalent validating blocks and/or transactions for a
                        distributed ledger/blockchain network using a
                        proof-of-stake consensus algorithm.
                    </p>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button
                        color="secondary"
                        onClick={() => {
                            setLoading(true);
                            UserAPI.ApproveContract()
                                .then((s) => {
                                    dispatch(loginUserSuccess(s));
                                })
                                .finally((s) => {
                                    setLoading(false);
                                });
                        }}
                        variant="contained"
                    >
                        Agree
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={acknowledge} maxWidth="sm" fullWidth>
                <DialogContent>
                    <VerifyPin
                        id={currentUser.id}
                        onClose={() => {
                            setAcknowledge(false);
                        }}
                        isNotVerify={true}
                        onComplete={(code) => {
                            dispatch(
                                createPlanMemberRequest({
                                    planId: selectedPlan,
                                    buyAmount: parseFloat(amount),
                                    code: code
                                })
                            );
                            setAcknowledge(false);
                        }}
                    />
                </DialogContent>
            </Dialog>
            <Dialog maxWidth="md" fullWidth open={open}>
                <DialogTitle>
                    Plan Details{' '}
                    <Close
                        className="float-right"
                        onClick={() => {
                            setOpen(false);
                        }}
                    />
                </DialogTitle>
                <Divider />
                <DialogContent>
                    {!selectedPlan && (
                        <Grid container spacing={2}>
                            {plans.map((s, index) => {
                                return (
                                    <Grid item md={4} lg={4} sm={12} xs={12}>
                                        <Card
                                            elevation={
                                                selectedPlan === s._id ? 10 : 2
                                            }
                                            onClick={() => {
                                                dispatch(
                                                    fetchPlanRequest({
                                                        id: s._id,
                                                        amount: amount,
                                                        date: date
                                                    })
                                                );
                                                setSelectedPlan(s._id);
                                            }}
                                        >
                                            <CardHeader
                                                avatar={
                                                    <Avatar
                                                        style={{
                                                            background:
                                                                deepOrange[500],
                                                            color: '#FFFFFF'
                                                        }}
                                                    >
                                                        {index + 1}
                                                    </Avatar>
                                                }
                                                title={s.planName}
                                                subheader={
                                                    s.duration + ' months'
                                                }
                                            />
                                            <Divider />
                                            <CardContent>
                                                <ul className="stripe-ul1">
                                                    <li>
                                                        <span>
                                                            Monthly Reward :
                                                        </span>
                                                        <span>
                                                            {s.withdrawReturns}{' '}
                                                            %
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span>
                                                            Reward Returns :{' '}
                                                        </span>
                                                        <span>
                                                            {s.monthlyReturns} %
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span>
                                                            Reinvestment :{' '}
                                                        </span>
                                                        <span>
                                                            {s.reinvestment} %
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span>Gas Fees : </span>
                                                        <span>
                                                            {s.adminFees} %
                                                        </span>
                                                    </li>
                                                </ul>
                                            </CardContent>
                                            <CardActions>
                                                <Button
                                                    variant={
                                                        selectedPlan === s._id
                                                            ? 'contained'
                                                            : 'outlined'
                                                    }
                                                    color="primary"
                                                    fullWidth
                                                >
                                                    {selectedPlan === s._id
                                                        ? 'Selected'
                                                        : 'Select'}
                                                </Button>
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    )}

                    {selectedPlan && (
                        <Grid container spacing={2}>
                            <Grid item md={4} lg={4} sm={12} xs={12}>
                                <TextField
                                    onChange={(e) => {
                                        let amt = e.target.value;
                                        setAmount(amt);
                                        if (amt) {
                                            amt = parseInt(
                                                parseFloat(amt) /
                                                    walletInfo.tokenPrice.price
                                            );
                                            setDollar(amt);
                                            dispatch(
                                                fetchPlanRequest({
                                                    id: selectedPlan,
                                                    amount: e.target.value,
                                                    date: date
                                                })
                                            );
                                        } else {
                                            setDollar(0);
                                        }
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment>$</InputAdornment>
                                        )
                                    }}
                                    fullWidth
                                    variant="outlined"
                                    value={amount}
                                    margin="dense"
                                    label="Amount"
                                    helperText={`Minimum Amount is $10`}
                                />
                            </Grid>
                            <Grid item md={4} lg={4} sm={12} xs={12}>
                                <TextField
                                    fullWidth
                                    disabled
                                    variant="outlined"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment>
                                                {
                                                    process.env
                                                        .REACT_APP_TOKEN_SYMBOL
                                                }
                                            </InputAdornment>
                                        )
                                    }}
                                    value={dollar}
                                    margin="dense"
                                    helperText={`Your Wallet Balance : ${walletInfo.tokenBalance} ${process.env.REACT_APP_TOKEN_SYMBOL} Tokens`}
                                    label="Tokens"
                                />
                            </Grid>
                            <Grid item md={4} lg={4} sm={12} xs={12}>
                                <Button
                                    className="mt-3"
                                    disabled={
                                        !amount ||
                                        parseFloat(amount) < 10 ||
                                        parseFloat(walletInfo.tokenBalance) <
                                            dollar
                                    }
                                    onClick={() => {
                                        Swal.fire({
                                            title: 'Join Plan',
                                            text: 'Are you sure want to join this plan.',
                                            icon: 'question',
                                            showConfirmButton: true,
                                            showCancelButton: true,
                                            confirmButtonText: 'Yes , Proceed',
                                            cancelButtonText: 'Close'
                                        }).then((s) => {
                                            if (s.isConfirmed) {
                                                setAcknowledge(true);
                                            }
                                        });
                                    }}
                                    color="secondary"
                                    variant="contained"
                                    size="small"
                                >
                                    Join Now
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                    {selectedPlan && (
                        <Grid container>
                            <Grid item xs={4} sm={4}>
                                <ListItem>
                                    <ListItemAvatar>
                                        <AccountBalance />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            planDetail &&
                                            planDetail[planDetail.length - 1] &&
                                            planDetail[planDetail.length - 1]
                                                .amount + ' USD'
                                        }
                                        secondary="Maturity Amount"
                                    />
                                </ListItem>
                            </Grid>
                            <Grid item xs={4} sm={4}>
                                <ListItem>
                                    <ListItemAvatar>
                                        <AccountBalanceWallet />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={intAmount + ' USD'}
                                        secondary={'Reward Amount'}
                                    />
                                </ListItem>
                            </Grid>
                            <Grid item xs={4} sm={4}>
                                <ListItem>
                                    <ListItemAvatar>
                                        <ListAlt />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            (planDetail &&
                                                planDetail[
                                                    planDetail.length - 1
                                                ] &&
                                                parseFloat(
                                                    planDetail[
                                                        planDetail.length - 1
                                                    ].amount
                                                ) + parseFloat(intAmount)) +
                                            ' USD'
                                        }
                                        secondary={'Total Amount'}
                                    />
                                </ListItem>
                            </Grid>
                        </Grid>
                    )}
                    {selectedPlan && (
                        <table className="table table-striped mt-2">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Date</th>
                                    <th>Amount</th>
                                    <th>Reward</th>
                                    <th>Net Reward</th>
                                    <th>Reinvesment</th>
                                    <th>Liquidity</th>
                                    <th>Gas Fees</th>
                                </tr>
                            </thead>
                            <tbody>
                                {planDetail &&
                                    planDetail.map((s, index) => {
                                        return (
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td>{s.date}</td>
                                                <td>
                                                    {s.amount} {currency}
                                                </td>
                                                <td>
                                                    {s.interest} {currency}
                                                </td>
                                                <td>
                                                    {s.withdrawalAmount}{' '}
                                                    {currency}
                                                </td>
                                                <td>
                                                    {s.reinvest} {currency}
                                                </td>
                                                <td>
                                                    {s.liquidityFees} {currency}
                                                </td>
                                                <td>
                                                    {s.adminFees} {currency}
                                                </td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table>
                    )}
                </DialogContent>
            </Dialog>
            <TitleBar
                heading="Plans"
                secondary={''}
                icon={<AccountBalance fontSize="large" htmlColor="goldenrod" />}
            />
            <div className={classes.root}>
                <MyPlanDetail onOpen={() => setOpen(true)} />
            </div>
        </div>
    );
}
