import _ from 'underscore';

export function getCurrentUser(state) {
    return state.UserReducer.user;
}

export function getUserReferrals(state) {
    return state.UserReducer.referals;
}

export function getUserReferees(state) {
    return state.UserReducer.referees;
}

export function getUsers(state) {
    return state.UserReducer.users;
}

export function getWalletInformation(state) {
    return state.UserReducer.walletInfo;
}
