import {
    Avatar,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    makeStyles
} from '@material-ui/core';
import {
    AccountBalance,
    AccountBox,
    CalendarToday,
    Close,
    Email,
    Fingerprint,
    Phone,
    Star,
    Visibility
} from '@material-ui/icons';
import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'underscore';
import { fetchUserRequest } from '../../store/user/user.actions';
import { getUsers } from '../../store/user/user.selector';
import TitleBar from '../base/TitleBar';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper
    }
}));

export default function MemberContainer() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [selectedItem, setSelecteItem] = useState({});
    const [open, setOpen] = useState(false);

    useEffect(() => {
        dispatch(fetchUserRequest());
    }, []);

    const { users } = useSelector((state) => {
        return {
            users: getUsers(state)
        };
    }, []);

    useEffect(() => {
        setOpen(false);
        setSelecteItem({});
    }, [users]);

    const actionRenderer = () => {
        return {
            name: 'id',
            label: 'Action',
            options: {
                filter: false,
                customBodyRender: (value) => {
                    let dep = _.find(users, (x) => x._id === value);
                    return (
                        <Visibility
                            className={classes.icon}
                            color="secondary"
                            onClick={() => {
                                setOpen(true);
                                setSelecteItem(dep);
                            }}
                        />
                    );
                }
            }
        };
    };

    const columns = [
        {
            name: 'memberId',
            label: 'Member #',
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: 'name',
            label: 'Member Name',
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: 'phone',
            label: 'Mobile Number',
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: 'email',
            label: 'Email',
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: 'createdAt',
            label: 'Joined Date',
            options: {
                filter: true,
                sort: true
            }
        },

        // {
        //     name: 'refCount',
        //     label: 'Referral Count',
        //     options: {
        //         filter: true,
        //         sort: true,
        //     }
        // },
        actionRenderer()
    ];

    const datum = users.map((item) => {
        return {
            id: item._id,
            createdAt: moment(item.createdAt).format('lll'),
            memberId: item.memberId,
            deposit: item.depositAmount || 0,
            refCount: item.refCount,
            phone: item.mobileNumber,
            email: item.email,
            name: item.firstName + ' ' + item.lastName
        };
    });

    const options = {
        selectableRows: false,
        download: false,
        print: false
    };

    return (
        <div className="mb-4 pb-4">
            <Dialog
                maxWidth="md"
                fullWidth
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
            >
                <DialogTitle>
                    User Information{' '}
                    <Close
                        className="mt-1 float-right"
                        onClick={() => setOpen(false)}
                    />
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <List>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar className="bg-primary">
                                    <Fingerprint />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={selectedItem.memberId}
                                secondary={'Member ID'}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar className="bg-primary">
                                    <AccountBox />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    selectedItem.firstName +
                                    ' ' +
                                    selectedItem.lastName
                                }
                                secondary={'Member Name'}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar className="bg-primary">
                                    <Phone />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={selectedItem.mobileNumber}
                                secondary={'Mobile Number'}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar className="bg-primary">
                                    <Email />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={selectedItem.email}
                                secondary={'Email'}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar className="bg-primary">
                                    <CalendarToday />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={moment(selectedItem.createdAt).format(
                                    'lll'
                                )}
                                secondary={'Joined Date'}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar className="bg-primary">
                                    <CalendarToday />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={moment(selectedItem.updatedAt).format(
                                    'lll'
                                )}
                                secondary={'Updated Date'}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar className="bg-primary">
                                    <Star />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={selectedItem.referal}
                                secondary={'Referred By'}
                            />
                        </ListItem>
                    </List>
                </DialogContent>
            </Dialog>
            <TitleBar
                heading="Users"
                secondary={''}
                icon={<AccountBalance fontSize="large" htmlColor="goldenrod" />}
            />
            <div className={classes.root}>
                <MUIDataTable
                    title={"User's List"}
                    data={datum}
                    columns={columns}
                    options={options}
                />
            </div>
        </div>
    );
}
