import {
    Avatar,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TextField,
    makeStyles
} from '@material-ui/core';
import { deepOrange } from '@material-ui/core/colors';
import {
    AccountBalance,
    ArrowBackSharp,
    CloudDownload
} from '@material-ui/icons';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { downloadIllustrationReport } from '../../helpers/PDFGenerator';
import {
    fetchPlanRequest,
    fetchPlansRequest
} from '../../store/plan/plan.actions';
import { getPlanDetail, getPlans } from '../../store/plan/plan.selector';
import TitleBar from '../base/TitleBar';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    }
}));

export default function PlanContainer() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState('');
    const [amount, setAmount] = useState(100);
    const [date, setDate] = useState(moment().format('YYYY-MM-DD'));

    useEffect(() => {
        dispatch(fetchPlansRequest());
    }, []);

    const { plans, planDetail } = useSelector((state) => {
        return {
            plans: getPlans(state),
            planDetail: getPlanDetail(state)
        };
    }, []);

    return (
        <div className="mb-4 pb-4">
            <TitleBar
                heading="Plans"
                secondary={''}
                icon={<AccountBalance fontSize="large" htmlColor="goldenrod" />}
                action={
                    open ? (
                        <Button
                            className="text-white"
                            onClick={() => setOpen(false)}
                            startIcon={<ArrowBackSharp />}
                        >
                            Back
                        </Button>
                    ) : undefined
                }
            />
            <div className={classes.root}>
                {open ? (
                    <>
                        <Grid container spacing={2}>
                            <Grid item md={4} lg={4} sm={12} xs={12}>
                                <TextField
                                    onChange={(e) => {
                                        setAmount(e.target.value);
                                        dispatch(
                                            fetchPlanRequest({
                                                id: selectedPlan,
                                                amount: e.target.value,
                                                date: date
                                            })
                                        );
                                    }}
                                    fullWidth
                                    variant="outlined"
                                    value={amount}
                                    margin="dense"
                                    label="Amount"
                                />
                            </Grid>
                            <Grid item md={4} lg={4} sm={12} xs={12}>
                                <TextField
                                    onChange={(e) => {
                                        setDate(e.target.value);
                                        dispatch(
                                            fetchPlanRequest({
                                                id: selectedPlan,
                                                amount: amount,
                                                date: e.target.value
                                            })
                                        );
                                    }}
                                    fullWidth
                                    type="date"
                                    variant="outlined"
                                    defaultValue={moment().format('YYYY-MM-DD')}
                                    value={date}
                                    margin="dense"
                                    label="Date"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </Grid>
                            <Grid item md={4} lg={4} sm={12} xs={12}>
                                <Button
                                    onClick={() => {
                                        downloadIllustrationReport(planDetail);
                                    }}
                                    variant="contained"
                                    color="secondary"
                                    className="mt-2"
                                    fullWidth
                                    startIcon={<CloudDownload />}
                                >
                                    Download
                                </Button>
                            </Grid>
                        </Grid>
                        <table className="table table-striped mt-2">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Date</th>
                                    <th>Deposit Amount</th>
                                    <th>Reward</th>
                                    <th>Net Withdraw</th>
                                    <th>Gas Fees</th>
                                    <th>Liquidity</th>
                                    <th>Reinvestment</th>
                                </tr>
                            </thead>
                            <tbody>
                                {planDetail &&
                                    planDetail.map((s, index) => {
                                        return (
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td>{s.date}</td>
                                                <td>{s.amount} USD</td>
                                                <td>{s.interest} USD</td>
                                                <td>
                                                    {s.withdrawalAmount} USD
                                                </td>
                                                <td>{s.adminFees} USD</td>
                                                <td>{s.liquidityFees} USD</td>
                                                <td>{s.reinvest} USD</td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table>
                    </>
                ) : (
                    <Grid container spacing={2}>
                        {plans.map((s, index) => {
                            return (
                                <Grid item md={6} lg={6} sm={12} xs={12}>
                                    <Card variant="outlined">
                                        <CardHeader
                                            avatar={
                                                <Avatar
                                                    style={{
                                                        background:
                                                            deepOrange[500],
                                                        color: '#FFFFFF'
                                                    }}
                                                >
                                                    {index + 1}
                                                </Avatar>
                                            }
                                            title={s.planName}
                                            action={
                                                <>
                                                    <Button
                                                        color="primary"
                                                        variant="contained"
                                                        size="small"
                                                        onClick={() => {
                                                            setSelectedPlan(
                                                                s._id
                                                            );
                                                            dispatch(
                                                                fetchPlanRequest(
                                                                    {
                                                                        id: s._id,
                                                                        amount: amount,
                                                                        date: date
                                                                    }
                                                                )
                                                            );
                                                            setOpen(true);
                                                        }}
                                                    >
                                                        Calculate
                                                    </Button>
                                                </>
                                            }
                                            subheader={s.duration + ' months'}
                                        />
                                        <Divider />
                                        <CardContent>
                                            <ul className="stripe-ul">
                                                <li>
                                                    <span>
                                                        Monthly Reward :
                                                    </span>
                                                    <span>
                                                        {s.withdrawReturns} %
                                                    </span>
                                                </li>
                                                <li>
                                                    <span>Net Withdraw: </span>
                                                    <span>
                                                        {Math.ceil(
                                                            parseFloat(
                                                                (s.monthlyReturns *
                                                                    100) /
                                                                    s.withdrawReturns
                                                            )
                                                        )}{' '}
                                                        %
                                                    </span>
                                                </li>
                                                <li>
                                                    <span>Reinvestment : </span>
                                                    <span>
                                                        {parseFloat(
                                                            (s.reinvestment *
                                                                100) /
                                                                s.withdrawReturns
                                                        )}{' '}
                                                        %
                                                    </span>
                                                </li>
                                                <li>
                                                    <span>Liquidity : </span>
                                                    <span>
                                                        {parseFloat(
                                                            (s.liquidity *
                                                                100) /
                                                                s.withdrawReturns
                                                        )}{' '}
                                                        %
                                                    </span>
                                                </li>
                                                <li>
                                                    <span>Gas Fees : </span>
                                                    <span>
                                                        {parseFloat(
                                                            (s.adminFees *
                                                                100) /
                                                                s.withdrawReturns
                                                        )}{' '}
                                                        %
                                                    </span>
                                                </li>
                                            </ul>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            );
                        })}
                    </Grid>
                )}
            </div>
        </div>
    );
}
