import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Container,
    TextField
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import MuiPhoneNumber from 'material-ui-phone-number';
import React, { useState } from 'react';
import Swal from 'sweetalert2';
import {
    IsNullOrEmpty,
    clearToken,
    validPassword,
    validPhone
} from '../../helpers/utility';
import UserAPI from '../../store/user/user.api';

function UpdateInfo(props) {
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [cpassword, setCPassword] = useState('');
    const [isValidated, setValidated] = useState(false);

    const validate = () => {
        return (
            IsNullOrEmpty(email) ||
            IsNullOrEmpty(newPassword) ||
            IsNullOrEmpty(cpassword) ||
            IsNullOrEmpty(mobile) ||
            !validPhone(mobile) ||
            !validPassword(cpassword) ||
            newPassword !== cpassword
        );
    };

    const handleUpdateInfo = (e) => {
        e.preventDefault();
        const isNotValid = validate();
        if (isNotValid) {
            setValidated(true);
        } else {
            UserAPI.UpdateInfo({
                email: email,
                password: newPassword,
                country: mobile.slice(0, 2),
                mobileNumber: mobile.slice(3, mobile.length)
            }).then((s) => {
                Swal.fire({
                    icon: 'success',
                    title: 'Password Update',
                    text: 'Please login again',
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonText: 'Close'
                }).then((x) => {
                    if (x.isDismissed) {
                        clearToken();
                    }
                });
            });
        }
    };

    return (
        <Container maxWidth="sm" fullWidth className="mt-4">
            <form onSubmit={(e) => handleUpdateInfo(e)}>
                <Card>
                    <CardHeader
                        title={'Update Information'}
                        subheader="Email, Phone Number, Password"
                        action={<Close onClick={() => props.onClose()} />}
                    />
                    <CardContent>
                        <div className="form-group">
                            <MuiPhoneNumber
                                variant="outlined"
                                className="px-2"
                                fullWidth
                                value={mobile}
                                margin="dense"
                                defaultCountry={'in'}
                                autoFormat={false}
                                onChange={(value) => setMobile(value)}
                                error={
                                    isValidated &&
                                    (IsNullOrEmpty(mobile) ||
                                        !validPhone(mobile))
                                }
                                helperText={
                                    isValidated &&
                                    (IsNullOrEmpty(mobile) ||
                                        !validPhone(mobile))
                                        ? 'Mobile Number should be valid'
                                        : ''
                                }
                            />
                        </div>
                        <div className="form-group">
                            <TextField
                                label="Email"
                                placeholder="Email"
                                variant="outlined"
                                color="primary"
                                fullWidth
                                size="small"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                helperText={
                                    isValidated && IsNullOrEmpty(email)
                                        ? 'Please enter old password'
                                        : ''
                                }
                                error={isValidated && IsNullOrEmpty(email)}
                            />
                        </div>
                        <div className="form-group">
                            <TextField
                                label="New Password"
                                placeholder="New Password"
                                variant="outlined"
                                type="password"
                                color="primary"
                                fullWidth
                                size="small"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                helperText={
                                    isValidated && IsNullOrEmpty(newPassword)
                                        ? 'Please enter password'
                                        : isValidated &&
                                          newPassword !== cpassword
                                        ? 'Password should match'
                                        : ''
                                }
                                error={
                                    isValidated &&
                                    (IsNullOrEmpty(newPassword) ||
                                        newPassword !== cpassword)
                                }
                            />
                        </div>
                        <div className="form-group">
                            <TextField
                                label="Confirm Password"
                                placeholder="Confirm Password"
                                type="password"
                                variant="outlined"
                                color="primary"
                                fullWidth
                                size="small"
                                value={cpassword}
                                onChange={(e) => setCPassword(e.target.value)}
                                helperText={
                                    isValidated && IsNullOrEmpty(cpassword)
                                        ? 'Please enter confirm password'
                                        : ''
                                }
                                error={isValidated && IsNullOrEmpty(cpassword)}
                            />
                        </div>
                    </CardContent>
                    <CardActions>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => props.onClose()}
                        >
                            Close
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            fullWidth
                        >
                            Update Info
                        </Button>
                    </CardActions>
                </Card>
            </form>
        </Container>
    );
}

export default UpdateInfo;
