import {
    Box,
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    InputAdornment,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import {
    AccountBoxSharp,
    Code,
    Email,
    Person,
    PersonAdd,
    PersonAddOutlined,
    PersonOutline,
    PhoneAndroid,
    Save
} from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import MuiPhoneNumber from 'material-ui-phone-number';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import {
    IsNullOrEmpty,
    validEmail,
    validPassword,
    validPhone
} from '../../helpers/utility';
import { registerUserRequest } from '../../store/user/user.actions';
import UserAPI from '../../store/user/user.api';
var random = require('random-name');
var randomMobile = require('random-mobile');

export default function SignUp() {
    const dispatch = useDispatch();
    const location = useLocation();
    // let fName = random.first();
    // let lName = random.last();
    const [values, setValues] = React.useState({
        firstName: '',
        lastName: '',
        country: '+91',
        mobileNumber: '',
        email: '',
        password: '',
        confirmPassword: '',
        referedBy: ''
    });

    const [acknowlege, setAcknowlege] = useState(false);
    const [referralName, setReferralName] = useState('');

    const handleReferral = () => {
        if (!IsNullOrEmpty(values.referedBy)) {
            UserAPI.GetMemberDetail(values.referedBy).then((d) => {
                setReferralName(d);
            });
        } else {
            setReferralName('Company');
        }
    };

    useEffect(() => {
        if (location.search) {
            const query = new URLSearchParams(location.search);
            const id = query.get('ref');
            if (id) {
                setValues({ ...values, referedBy: id });
            } else {
                setValues({ ...values, referedBy: '' });
            }
        }
    }, [location]);

    const [isValidated, setValidated] = React.useState(false);

    const validate = () => {
        if (IsNullOrEmpty(values.firstName)) {
            return false;
        }
        if (IsNullOrEmpty(values.lastName)) {
            return false;
        }
        if (
            IsNullOrEmpty(values.mobileNumber) ||
            !validPhone(values.mobileNumber)
        ) {
            return false;
        }
        if (IsNullOrEmpty(values.email) || !validEmail(values.email)) {
            return false;
        }
        if (IsNullOrEmpty(values.password) || !validPassword(values.password)) {
            return false;
        }
        if (IsNullOrEmpty(values.confirmPassword)) {
            return false;
        }
        if (values.confirmPassword !== values.password) {
            return false;
        }
        return true;
    };

    const handleReset = () => {
        setValidated(false);
        setValues({
            firstName: '',
            lastName: '',
            country: '',
            mobileNumber: '',
            email: '',
            password: '',
            confirmPassword: ''
        });
    };

    const handleSave = () => {
        if (validate()) {
            setAcknowlege(true);
        } else {
            setValidated(true);
        }
    };

    return (
        <Container maxWidth="md">
            <h4 className="text-center p-4">Sign Up</h4>
            <Dialog
                maxWidth="md"
                open={acknowlege}
                onClose={() => setAcknowlege(false)}
            >
                <DialogTitle>Registration Information</DialogTitle>
                <Divider />
                <DialogContent>
                    <Alert severity="error">
                        Please verify the information and confirm
                    </Alert>
                    <List>
                        <ListItem>
                            <ListItemAvatar>
                                <AccountBoxSharp />
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    values.firstName + ' ' + values.lastName
                                }
                                secondary="Name"
                            />
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemAvatar>
                                <Email />
                            </ListItemAvatar>
                            <ListItemText
                                primary={values.email}
                                secondary="Email"
                            />
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemAvatar>
                                <PhoneAndroid />
                            </ListItemAvatar>
                            <ListItemText
                                primary={values.mobileNumber}
                                secondary="Mobile Number"
                            />
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemAvatar>
                                <PersonAdd />
                            </ListItemAvatar>
                            <ListItemText
                                primary={values.referedBy}
                                secondary="Referred By"
                            />
                        </ListItem>
                    </List>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Box
                        display={'flex'}
                        justifyContent={'space-between'}
                        width={'100%'}
                        alignItems={'center'}
                    >
                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={() => {
                                setAcknowlege(false);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => {
                                setAcknowlege(false);
                                dispatch(
                                    registerUserRequest({
                                        firstName: values.firstName,
                                        lastName: values.lastName,
                                        country: values.mobileNumber.slice(
                                            0,
                                            2
                                        ),
                                        mobileNumber: values.mobileNumber.slice(
                                            3,
                                            values.mobileNumber.length
                                        ),
                                        email: values.email,
                                        password: values.password,
                                        referal: values.referedBy || 'ADM00001'
                                    })
                                );
                            }}
                        >
                            I Agree
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
            <Grid container spacing={4}>
                <Grid xs={12} sm={12} md={6} lg={6}>
                    <TextField
                        variant="outlined"
                        placeholder="First Name"
                        label="First Name"
                        value={values.firstName}
                        onChange={(e) =>
                            setValues({ ...values, firstName: e.target.value })
                        }
                        fullWidth
                        margin="dense"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Person color="disabled" />
                                </InputAdornment>
                            )
                        }}
                        className="px-2"
                        error={isValidated && IsNullOrEmpty(values.firstName)}
                        helperText={
                            isValidated && IsNullOrEmpty(values.firstName)
                                ? 'First Name should be valid'
                                : ''
                        }
                    />
                </Grid>
                <Grid xs={12} sm={12} md={6} lg={6}>
                    <TextField
                        variant="outlined"
                        placeholder="Last Name"
                        label="Last Name"
                        value={values.lastName}
                        onChange={(e) =>
                            setValues({ ...values, lastName: e.target.value })
                        }
                        fullWidth
                        margin="dense"
                        className="px-2"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <PersonOutline color="disabled" />
                                </InputAdornment>
                            )
                        }}
                        error={isValidated && IsNullOrEmpty(values.lastName)}
                        helperText={
                            isValidated && IsNullOrEmpty(values.lastName)
                                ? 'Last Name should be valid'
                                : ''
                        }
                    />
                </Grid>
                <Grid xs={12} sm={12} md={6} lg={6}>
                    <MuiPhoneNumber
                        variant="outlined"
                        className="px-2"
                        fullWidth
                        value={values.mobileNumber}
                        margin="dense"
                        defaultCountry={'in'}
                        autoFormat={false}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <PhoneAndroid color="disabled" />
                                </InputAdornment>
                            )
                        }}
                        onChange={(value) =>
                            setValues({ ...values, mobileNumber: value })
                        }
                        error={
                            isValidated &&
                            (IsNullOrEmpty(values.mobileNumber) ||
                                !validPhone(values.mobileNumber))
                        }
                        helperText={
                            isValidated &&
                            (IsNullOrEmpty(values.mobileNumber) ||
                                !validPhone(values.mobileNumber))
                                ? 'Mobile Number should be valid'
                                : ''
                        }
                    />
                </Grid>
                <Grid xs={12} sm={12} md={6} lg={6}>
                    <TextField
                        variant="outlined"
                        placeholder="Email"
                        label="Email"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Email color="disabled" />
                                </InputAdornment>
                            )
                        }}
                        value={values.email}
                        type="email"
                        onChange={(e) =>
                            setValues({ ...values, email: e.target.value })
                        }
                        fullWidth
                        margin="dense"
                        className="px-2"
                        error={
                            isValidated &&
                            (IsNullOrEmpty(values.email) ||
                                !validEmail(values.email))
                        }
                        helperText={
                            isValidated &&
                            (IsNullOrEmpty(values.email) ||
                                !validEmail(values.email))
                                ? 'Email should be valid'
                                : ''
                        }
                    />
                </Grid>
                <Grid xs={12} sm={12} md={6} lg={6}>
                    <TextField
                        variant="outlined"
                        placeholder="Password"
                        label="Password"
                        type="password"
                        value={values.password}
                        onChange={(e) =>
                            setValues({ ...values, password: e.target.value })
                        }
                        fullWidth
                        margin="dense"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Code color="disabled" />
                                </InputAdornment>
                            )
                        }}
                        className="px-2"
                        error={
                            isValidated &&
                            (IsNullOrEmpty(values.password) ||
                                !validPassword(values.password))
                        }
                        helperText={
                            isValidated &&
                            (IsNullOrEmpty(values.password) ||
                                !validPassword(values.password))
                                ? 'Password should be valid'
                                : ''
                        }
                    />
                </Grid>
                <Grid xs={12} sm={12} md={6} lg={6}>
                    <TextField
                        variant="outlined"
                        placeholder="Confirm Password"
                        label="Confirm Password"
                        type="password"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Code color="disabled" />
                                </InputAdornment>
                            )
                        }}
                        value={values.confirmPassword}
                        onChange={(e) =>
                            setValues({
                                ...values,
                                confirmPassword: e.target.value
                            })
                        }
                        fullWidth
                        margin="dense"
                        className="px-2"
                        error={
                            isValidated &&
                            (IsNullOrEmpty(values.confirmPassword) ||
                                !validPassword(values.confirmPassword) ||
                                values.password !== values.confirmPassword)
                        }
                        helperText={
                            isValidated &&
                            (IsNullOrEmpty(values.confirmPassword) ||
                                !validPassword(values.confirmPassword))
                                ? 'Password should be valid'
                                : values.password !== values.confirmPassword
                                ? 'Password should match'
                                : ''
                        }
                    />
                </Grid>
                <Grid xs={12} sm={12} md={6} lg={6}>
                    <TextField
                        variant="outlined"
                        placeholder="Referred By"
                        label="Referred By"
                        value={values.referedBy}
                        onChange={(e) => {
                            setReferralName('');
                            setValues({ ...values, referedBy: e.target.value });
                        }}
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <PersonAdd color="disabled" />
                                </InputAdornment>
                            )
                        }}
                        margin="dense"
                        className="px-2"
                        onBlur={() => handleReferral()}
                        helperText={
                            "Leave blank, if you don't have any referal now"
                        }
                    />
                </Grid>
                <Grid xs={12} sm={12} md={6} lg={6}>
                    <TextField
                        variant="outlined"
                        placeholder="Referred Name"
                        label="Referred Name"
                        disabled
                        value={referralName}
                        fullWidth
                        margin="dense"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <PersonAddOutlined color="disabled" />
                                </InputAdornment>
                            )
                        }}
                        className="px-2"
                        helperText={'Referred Name'}
                    />
                </Grid>
                <Grid xs={12} sm={12} className="p-2">
                    <Container maxWidth="sm">
                        <Grid container>
                            <Grid xs={12} sm={12} md={6} lg={6} className="p-2">
                                <Button
                                    startIcon={<Save />}
                                    variant="contained"
                                    fullWidth
                                    color="primary"
                                    onClick={() => handleSave()}
                                >
                                    Register Now
                                </Button>
                            </Grid>
                            <Grid xs={12} sm={12} md={6} lg={6} className="p-2">
                                <Button
                                    variant="outlined"
                                    fullWidth
                                    color="primary"
                                    onClick={() => handleReset()}
                                >
                                    Reset
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>
            </Grid>
            <p className="text-center mt-3">
                <Link to="/login" variant="body2">
                    {'Already have an account? Sign In'}
                </Link>
            </p>
        </Container>
    );
}
