import HttpHelper from '../../helpers/httpHelper';
import {
    changeNomineeURI,
    changeto2FAUrl,
    changetoPINUrl,
    createWhitelistUrl,
    get2FASetup,
    getActiveIDUrl,
    getChangePasswordURI,
    getForgotPasswordURI,
    getMemberDetailUrl,
    getPinSetup,
    getReferralReportUrl,
    getRewardReportUrl,
    getSendEmailOTP,
    getSendPhoneOTP,
    getStatUrl,
    setSendEmailOTP,
    setSendPhoneOTP,
    verify2FAUrl
} from './user.uri';

require('dotenv').config();
const headerInfo = {};

class UserAPI {
    static async UpdateInfo(req) {
        let url = process.env.REACT_APP_API_URL + '/member';
        return HttpHelper.httpRequest(url, 'PUT', headerInfo, req);
    }

    static async ApproveContract() {
        let url = process.env.REACT_APP_API_URL + '/contract/approve';
        return HttpHelper.httpRequest(url, 'POST', headerInfo, {
            amount: 1000000
        });
    }

    static async RegisterUser(req) {
        let url = process.env.REACT_APP_API_URL + '/member';
        return HttpHelper.httpRequest(url, 'POST', headerInfo, req);
    }

    static async GetUsers() {
        let url = process.env.REACT_APP_API_URL + '/admin/members';
        return HttpHelper.httpRequest(url, 'GET', headerInfo);
    }

    static async GetWalletInfo() {
        let url = process.env.REACT_APP_API_URL + '/members/wallet/info';
        return HttpHelper.httpRequest(url, 'GET', headerInfo);
    }

    static async GetCurrentUser() {
        let url = process.env.REACT_APP_API_URL + '/member';
        return HttpHelper.httpRequest(url, 'GET', headerInfo);
    }

    static async UpdateCurrentUser(req) {
        let url = process.env.REACT_APP_API_URL + '/member';
        return HttpHelper.httpRequest(url, 'PUT', headerInfo, req);
    }

    static async GetCurrentUserReferrals() {
        let url = process.env.REACT_APP_API_URL + '/member/children';
        return HttpHelper.httpRequest(url, 'GET', headerInfo);
    }

    static async GetCurrentUserReferees() {
        let url = process.env.REACT_APP_API_URL + '/member/parent';
        return HttpHelper.httpRequest(url, 'GET', headerInfo);
    }

    static async SetEmailOTP(req) {
        let url = getSendEmailOTP();
        return HttpHelper.httpRequest(url, 'PATCH', {});
    }

    static async SetPhoneOTP(req) {
        let url = getSendPhoneOTP();
        return HttpHelper.httpRequest(url, 'PATCH', {});
    }

    static async VerifyEmailOTP(req) {
        let url = setSendEmailOTP(req);
        return HttpHelper.httpRequest(url, 'PATCH', {});
    }

    static async VerifyPhoneOTP(req) {
        let url = setSendPhoneOTP(req);
        return HttpHelper.httpRequest(url, 'PATCH', {});
    }

    static async Get2FASetUpCode(req) {
        let url = get2FASetup();
        return HttpHelper.httpRequest(url, 'PATCH', {});
    }

    static async Verify2FACode(code) {
        let url = verify2FAUrl();
        return HttpHelper.httpRequest(
            url,
            'PATCH',
            {},
            {
                code: code
            }
        );
    }

    static async GetMemberDetail(memberId) {
        let url = getMemberDetailUrl(memberId);
        return HttpHelper.httpRequest(url, 'GET', {});
    }

    static async VerifyPinCode(code) {
        let url = getPinSetup();
        return HttpHelper.httpRequest(
            url,
            'PATCH',
            {},
            {
                code: code
            }
        );
    }

    static async CreateWhitelist(req) {
        let url = createWhitelistUrl(req.code);
        return HttpHelper.httpRequest(url, 'POST', {}, req.data);
    }

    static async GetStats() {
        let url = getStatUrl();
        return HttpHelper.httpRequest(url, 'GET', {});
    }

    static async ForgotPassword(req) {
        let url = getForgotPasswordURI(req);
        return HttpHelper.httpRequest(url, 'POST', {});
    }

    static async ChangeNominee(req) {
        let url = changeNomineeURI();
        return HttpHelper.httpRequest(url, 'PATCH', {}, req);
    }

    static async ChangePassword(req) {
        let url = getChangePasswordURI();
        return HttpHelper.httpRequest(url, 'PATCH', {}, req);
    }

    static async ChangeTo2FA(req) {
        let url = changeto2FAUrl(req);
        return HttpHelper.httpRequest(url, 'PATCH', {});
    }

    static async ChangeToPIN(req) {
        let url = changetoPINUrl(req.code);
        return HttpHelper.httpRequest(
            url,
            'PATCH',
            {},
            {
                code: req.pin
            }
        );
    }

    static async GetActiveIDreport() {
        let url = getActiveIDUrl();
        return HttpHelper.httpRequest(url, 'GET', {});
    }

    static async GetReferralreport() {
        let url = getReferralReportUrl();
        return HttpHelper.httpRequest(url, 'GET', {});
    }

    static async GetRewardReport() {
        let url = getRewardReportUrl();
        return HttpHelper.httpRequest(url, 'GET', {});
    }
}

export default UserAPI;
