import pdfMake from 'pdfmake/build/pdfmake.min';
import vfsFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';
import { decodeToken, IsNullOrEmpty } from './utility';
import _ from 'underscore';
require('dotenv').config();

export function generatePdf(
    data,
    title,
    text,
    pageSize = 'A3',
    pageOrientation = 'landscape'
) {
    let currentUser = decodeToken();
    var docDefinition = {
        pageSize: pageSize,
        pageOrientation: pageOrientation,
        pageMargins: [40, 60, 40, 60],
        footer: function (currentPage, pageCount) {
            return [
                {
                    canvas: [
                        {
                            type: 'line',
                            x1: 40,
                            y1: 10,
                            x2: 1140,
                            y2: 10,
                            lineWidth: 0.5
                        }
                    ]
                },
                {
                    text:
                        'Report Generated at ' +
                        moment().format('lll') +
                        ' by ' +
                        currentUser.email,
                    style: 'footerText',
                    margin: [40, 10, 20, 0]
                },
                {
                    text: currentPage.toString() + ' of ' + pageCount,
                    style: 'footer',
                    margin: [20, 10, 20, 0]
                }
            ];
        },
        header: function (currentPage, pageCount, pageSize) {
            return [
                { text: title, style: 'header', margin: [0, 20, 0, 0] },
                {
                    canvas: [
                        {
                            type: 'line',
                            x1: 40,
                            y1: 0,
                            x2: 1140,
                            y2: 0,
                            lineWidth: 2
                        }
                    ]
                }
            ];
        },
        watermark: {
            text: 'Coin Shoppy Confidential',
            opacity: 0.3,
            bold: true,
            italics: false,
            fontSize: 50
        },
        content: [
            text,
            {
                style: 'table',
                heights: 40,
                padding: [10, 20, 10, 20],
                table: {
                    body: data
                },
                layout: {
                    hLineWidth: function (i, node) {
                        return i === 0 || i === node.table.body.length ? 2 : 1;
                    },
                    vLineWidth: function (i, node) {
                        return i === 0 || i === node.table.widths.length
                            ? 2
                            : 1;
                    },
                    hLineColor: function (i, node) {
                        return i === 0 || i === node.table.body.length
                            ? 'black'
                            : 'gray';
                    },
                    vLineColor: function (i, node) {
                        return i === 0 || i === node.table.widths.length
                            ? 'black'
                            : 'gray';
                    },
                    paddingLeft: function (i, node) {
                        return 6;
                    },
                    paddingRight: function (i, node) {
                        return 6;
                    },
                    paddingTop: function (i, node) {
                        return 10;
                    },
                    paddingBottom: function (i, node) {
                        return 10;
                    },
                    fillColor: function (rowIndex, node, columnIndex) {
                        return rowIndex === 0
                            ? '#CECECE'
                            : rowIndex % 2 === 0
                            ? '#F4F4F4'
                            : '#FFFFFF';
                    }
                }
            }
        ],
        styles: {
            header: {
                fontSize: 25,
                bold: true,
                alignment: 'center',
                lineHeight: 1.2
            },
            footerText: {
                alignment: 'left',
                italics: true,
                bold: true,
                lineHeight: 1
            },
            footer: {
                fontSize: 12,
                italics: true,
                alignment: 'right',
                lineHeight: 1
            },
            table: {
                margin: [0, 10, 0, 0]
            }
        }
    };

    const { vfs } = vfsFonts.pdfMake;
    pdfMake.vfs = vfs;
    pdfMake
        .createPdf(docDefinition)
        .download(title + '_' + moment().unix().toString());
}

export function downloadIllustrationReport(items) {
    let data = [];
    data.push([
        'S.No',
        'Date',
        'Amount',
        'Reward',
        'Net Withdraw',
        'Gas Fees',
        'Liquidity',
        'Reinvestment'
    ]);
    let cnt = 0;
    items.forEach((item, index) => {
        cnt = cnt + 1;
        data.push([
            { text: cnt },
            { text: item.date },
            { text: item.amount },
            { text: item.interest },
            { text: item.withdrawalAmount },
            { text: item.adminFees },
            { text: item.liquidity || item.liquidityFees },
            { text: item.reinvest }
        ]);
    });

    generatePdf(data, 'Plan Illustration', '', 'A4', 'portrait');
}

export function downloadSwapReport(deposits, type) {
    let data = [];
    data.push([
        'S.No',
        'Transaction Date',
        'Reference No',
        'Transaction ID',
        'Coins',
        'USD',
        'Fees',
        'Spent Hash',
        'Buy Hash',
        'Fees Hash'
    ]);
    let cnt = 0;

    deposits.forEach((item, index) => {
        cnt = cnt + 1;
        data.push([
            { text: cnt },
            { text: moment(item.createdAt).format('lll'), noWrap: true },
            { text: item.referenceNo },
            { text: item.transactionId },
            { text: item.transactionCoins + ' ' + item.coinType, noWrap: true },
            {
                text: item.transactionTokens + ' ' + item.tokenSymbol,
                noWrap: true
            },
            {
                text: item.transactionCharge + ' ' + item.coinType,
                noWrap: true
            },
            {
                text: !IsNullOrEmpty(item.spentHash) ? 'Hash' : '',
                link: 'https://bscscan.com/tx/' + item.spentHash,
                decoration: 'underline'
            },
            {
                text: !IsNullOrEmpty(item.receiveHash) ? 'Hash' : '',
                link: 'https://bscscan.com/tx/' + item.receiveHash,
                decoration: 'underline'
            },
            {
                text: !IsNullOrEmpty(item.transactionChargeHash) ? 'Hash' : '',
                link: 'https://bscscan.com/tx/' + item.transactionChargeHash,
                decoration: 'underline'
            }
        ]);
    });

    generatePdf(data, type, '');
}

export function downloadMonthlyReTopupReport(planMembers, type) {
    let data = [];
    data.push([
        'S.No',
        'Joined Date',
        'Maturity Date',
        'Transaction Date',
        'Tokens',
        'USD',
        'Reinvest Amount',
        'Status'
    ]);
    let cnt = 0;

    planMembers.forEach((item, index) => {
        item.returns.forEach((item1, index1) => {
            cnt = cnt + 1;
            debugger;
            if (
                moment(item1.date).format('MM-YYYY') ===
                moment().format('MM-YYYY')
            ) {
                data.push([
                    { text: cnt },
                    {
                        text: moment(item.createdAt).format('lll'),
                        noWrap: true
                    },
                    {
                        text: moment(item.maturityDate).format('lll'),
                        noWrap: true
                    },
                    { text: moment(item1.date).format('lll'), noWrap: true },
                    { text: item.token },
                    { text: item.amount },
                    { text: item1.reinvest + ' USD' },
                    { text: item1.status }
                ]);
            }
        });
    });

    generatePdf(data, type, '');
}

export function downloadReTopupReport(planMembers, type) {
    let data = [];
    data.push([
        'S.No',
        'Joined Date',
        'Maturity Date',
        'Transaction Date',
        'Tokens',
        'USD',
        'Reinvest Amount',
        'Status'
    ]);
    let cnt = 0;

    planMembers.forEach((item, index) => {
        item.returns.forEach((item1, index1) => {
            cnt = cnt + 1;
            debugger;
            data.push([
                { text: cnt },
                { text: moment(item.createdAt).format('lll'), noWrap: true },
                { text: moment(item.maturityDate).format('lll'), noWrap: true },
                { text: moment(item1.date).format('lll'), noWrap: true },
                { text: item.token },
                { text: item.amount },
                { text: item1.reinvest + ' USD' },
                { text: item1.status }
            ]);
        });
    });

    generatePdf(data, type, '');
}

export function downloadTeamReTopupReport(planMembers, type) {
    let data = [];
    data.push([
        'S.No',
        'Member ID',
        'Joined Date',
        'Maturity Date',
        'Transaction Date',
        'Tokens',
        'USD',
        'Reinvest Amount',
        'Status'
    ]);
    let cnt = 0;

    planMembers.forEach((item, index) => {
        item.returns.forEach((item1, index1) => {
            cnt = cnt + 1;
            data.push([
                { text: cnt },
                { text: item.memberId },
                { text: moment(item.createdAt).format('lll'), noWrap: true },
                { text: moment(item.maturityDate).format('lll'), noWrap: true },
                { text: moment(item1.date).format('lll'), noWrap: true },
                { text: item.token },
                { text: item.amount },
                { text: item1.reinvest + ' USD' },
                { text: item1.status }
            ]);
        });
    });

    generatePdf(data, type, '');
}

export function downloadWithdrawReport(withdraws) {
    let data = [];
    data.push([
        'S.No',
        'Transaction Date',
        'Address',
        'Reference No',
        'Transaction ID',
        'Transaction Hash',
        'Amount'
    ]);
    let cnt = 0;

    withdraws.forEach((item, index) => {
        debugger;
        cnt = cnt + 1;
        data.push([
            { text: cnt },
            { text: moment(item.createdAt).format('lll'), noWrap: true },
            { text: item.address },
            { text: item.referenceNo },
            { text: item.transactionId },
            { text: item.transactionhash },
            {
                text:
                    (item.method === 'USDT' ? item.coins : item.amount) +
                    ' ' +
                    (item.isToken ? item.tokenSymbol : item.coinType),
                noWrap: true
            }
        ]);
    });

    generatePdf(data, 'Withdraw Report', '', 'A2');
}

export function downloadRewardReport(referals) {
    referals = _.sortBy(referals, 'memberId');
    referals = referals.filter((t) => t.method === 'Reward');
    let data = [];
    data.push([
        'S.No',
        'Amount',
        'Type',
        'Transaction Time',
        'From',
        'Reward',
        'Status',
        'Hash',
        'Last Modified On'
    ]);
    let cnt = 0;

    referals.forEach((item, index) => {
        cnt = cnt + 1;
        data.push([
            { text: cnt },
            { text: item.planMembers[0].amount + ' USD' },
            { text: item.type },
            { text: moment(item.createdAt).format('lll') },
            {
                text: item.createdBy
            },
            { text: item.amount },
            { text: item.status },
            { text: item.transactionHash },
            { text: moment(item.updatedAt).format('lll') }
        ]);
    });

    generatePdf(data, 'Reward Report', '', 'A3');
}

export function downloadRefReport(referals) {
    referals = _.sortBy(referals, 'memberId');
    referals = referals.filter((t) => t.method === 'Referral');
    let data = [];
    data.push([
        'S.No',
        'Amount',
        'Type',
        'Transaction Time',
        'From',
        'Reward',
        'Status',
        'Hash',
        'Last Modified On'
    ]);
    let cnt = 0;

    referals.forEach((item, index) => {
        cnt = cnt + 1;
        data.push([
            { text: cnt },
            { text: item.planMembers[0].amount + ' USD' },
            { text: item.type },
            { text: moment(item.createdAt).format('lll') },
            {
                text: item.createdBy
            },
            { text: item.amount },
            { text: item.status },
            { text: item.transactionHash },
            { text: moment(item.updatedAt).format('lll') }
        ]);
    });

    generatePdf(data, 'Referral Report', '', 'A3');
}

export function downloadReTopReport(referals) {
    referals = _.sortBy(referals, 'memberId');
    let data = [];
    data.push([
        'S.No',
        'Type',
        'Transaction Time',
        'From',
        'To',
        'Name',
        'Email',
        'Mobile Number',
        'Reward',
        'Status'
    ]);
    let cnt = 0;

    referals.forEach((item, index) => {
        cnt = cnt + 1;
        data.push([
            { text: cnt },
            { text: item.type },
            { text: moment(item.createdAt).format('lll') },
            {
                text: item.createdBy
            },
            { text: item.memberId },
            {
                text: item.members[0].firstName + ' ' + item.members[0].lastName
            },
            { text: item.members[0].email },
            { text: item.members[0].mobileNumber },
            { text: item.reinvestment },
            { text: item.status }
        ]);
    });

    generatePdf(data, 'Retopup Report', '', 'A3');
}

export function downloadReTopCommissionReport(withdraws) {
    let data = [];
    data.push([
        'S.No',
        'Method',
        'Created Date',
        'Transaction Date',
        'Coins',
        'Type',
        'Status'
    ]);
    let cnt = 0;

    withdraws.forEach((item, index) => {
        cnt = cnt + 1;
        data.push([
            { text: cnt },
            { text: item.method },
            {
                text:
                    (item.planMembers &&
                        item.planMembers[0] &&
                        moment(item.planMembers[0].createdAt).format('lll')) ||
                    moment(item.createdAt).format('lll'),
                noWrap: true
            },
            { text: moment(item.createdAt).format('lll') },
            { text: '$ ' + item.reinvestment },
            { text: item.status },
            { text: item.type }
        ]);
    });

    generatePdf(data, 'Retopup Report', '', 'A3', 'Portrait');
}

export function downloadReferralReport(referals) {
    let data = [];
    data.push([
        'S.No',
        'Level',
        'Member ID',
        'Member Name',
        'Email',
        'Mobile Number',
        'Referred By',
        'Joined On',
        'Activated On'
    ]);

    referals.children = _.sortBy(referals.children, 'depth');

    referals.children.map((y, index) => {
        let value =
            referals &&
            referals.planMember &&
            referals.planMember.filter((t) => {
                return t.memberId === y.memberId;
            });
        let latestDate = '';
        if (!value || value.length === 0) {
            latestDate = 'Not Activated';
        } else {
            if (value.length > 1) {
                latestDate = _.sortBy(value, (t) => moment(t.createdAt).unix());
                latestDate = latestDate[0].createdAt;
                latestDate = moment(latestDate).format('LLL');
            } else {
                latestDate = value[0].createdAt;
                latestDate = moment(latestDate).format('LLL');
            }
        }

        data.push([
            { text: index + 1 },
            { text: y.depth + 1 },
            { text: y.memberId },
            { text: y.firstName + ' ' + y.lastName },
            {
                text: y.email.replace(
                    /^(.)(.*)(.@.*)$/,
                    (_, a, b, c) => a + b.replace(/./g, '*') + c
                )
            },
            { text: y.mobileNumber },
            { text: y.referal },
            { text: moment(y.createdAt).format('LLL') },
            { text: latestDate }
        ]);
    });
    generatePdf(data, 'Referral Report', '', 'A3');
}

export function downloadActiveIDReport(referals) {
    let data = [];
    data.push([
        'S.No',
        'Level',
        'Member ID',
        'Member Name',
        'Email',
        'Mobile Number',
        'Referred By',
        'Joined On',
        'Activated On'
    ]);

    referals.children = _.sortBy(referals.children, 'depth');

    referals.children.map((y, index) => {
        let value =
            referals &&
            referals.planMember &&
            referals.planMember.filter((t) => {
                return t.memberId === y.memberId;
            });
        let latestDate = '';
        if (!value || value.length === 0) {
            latestDate = 'Not Activated';
        } else {
            if (value.length > 1) {
                latestDate = _.sortBy(value, (t) => moment(t.createdAt).unix());
                latestDate = latestDate[0].createdAt;
                latestDate = moment(latestDate).format('LLL');
            } else {
                latestDate = value[0].createdAt;
                latestDate = moment(latestDate).format('LLL');
            }
        }

        if (latestDate !== 'Not Activated') {
            data.push([
                { text: index + 1 },
                { text: y.depth + 1 },
                { text: y.memberId },
                { text: y.firstName + ' ' + y.lastName },
                {
                    text: y.email.replace(
                        /^(.)(.*)(.@.*)$/,
                        (_, a, b, c) => a + b.replace(/./g, '*') + c
                    )
                },
                { text: y.mobileNumber },
                { text: y.referal },
                { text: moment(y.createdAt).format('LLL') },
                { text: latestDate }
            ]);
        }
    });
    generatePdf(data, 'Active ID Report', '', 'A3');
}

export function downloadInActiveIDReport(referals) {
    let data = [];
    data.push([
        'S.No',
        'Level',
        'Member ID',
        'Member Name',
        'Email',
        'Mobile Number',
        'Referred By',
        'Joined On'
    ]);

    referals.children = _.sortBy(referals.children, 'depth');

    referals.children.map((y, index) => {
        let value =
            referals &&
            referals.planMember &&
            referals.planMember.filter((t) => {
                return t.memberId === y.memberId;
            });
        let latestDate = '';
        if (!value || value.length === 0) {
            latestDate = 'Not Activated';
        } else {
            if (value.length > 1) {
                latestDate = _.sortBy(value, (t) => moment(t.createdAt).unix());
                latestDate = latestDate[0].createdAt;
                latestDate = moment(latestDate).format('LLL');
            } else {
                latestDate = value[0].createdAt;
                latestDate = moment(latestDate).format('LLL');
            }
        }

        if (latestDate === 'Not Activated') {
            data.push([
                { text: index + 1 },
                { text: y.depth + 1 },
                { text: y.memberId },
                { text: y.firstName + ' ' + y.lastName },
                {
                    text: y.email.replace(
                        /^(.)(.*)(.@.*)$/,
                        (_, a, b, c) => a + b.replace(/./g, '*') + c
                    )
                },
                { text: y.mobileNumber },
                { text: y.referal },
                { text: moment(y.createdAt).format('LLL') }
            ]);
        }
    });
    generatePdf(data, 'In Active ID Report', '', 'A3');
}

export function downloadPayoutReport(referals) {
    referals = _.sortBy(referals, 'memberId');
    let data = [];
    data.push([
        'S.No',
        'Amount',
        'Type',
        'Transaction Time',
        'From',
        'Reward',
        'Status',
        'Hash',
        'Last Modified On'
    ]);
    let cnt = 0;

    referals.forEach((item, index) => {
        cnt = cnt + 1;
        data.push([
            { text: cnt },
            { text: item.planMembers[0].amount + ' USD' },
            { text: item.type },
            { text: moment(item.createdAt).format('lll') },
            {
                text: item.createdBy
            },
            { text: item.amount },
            { text: item.status },
            { text: item.transactionHash },
            { text: moment(item.updatedAt).format('lll') }
        ]);
    });

    generatePdf(data, 'Payout Report', '', 'A3');
}
