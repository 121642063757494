import { action } from 'typesafe-actions';
import { UserActionTypes } from './user.model';

export const registerUserRequest = (req) =>
    action(UserActionTypes.REGISTER_USER_REQUEST, req);
export const registerUserSuccess = (res) =>
    action(UserActionTypes.REGISTER_USER_SUCCESS, res);
export const registerUserFailure = (err) =>
    action(UserActionTypes.REGISTER_USER_FAILURE, err);

export const fetchUserRequest = (req) =>
    action(UserActionTypes.FETCH_USER_REQUEST, req);
export const fetchUserSuccess = (res) =>
    action(UserActionTypes.FETCH_USER_SUCCESS, res);
export const fetchUserFailure = (err) =>
    action(UserActionTypes.FETCH_USER_FAILURE, err);

export const fetchUsersRequest = (req) =>
    action(UserActionTypes.FETCH_USERS_REQUEST, req);
export const fetchUsersSuccess = (res) =>
    action(UserActionTypes.FETCH_USERS_SUCCESS, res);
export const fetchUsersFailure = (err) =>
    action(UserActionTypes.FETCH_USERS_FAILURE, err);

export const updateUserRequest = (req) =>
    action(UserActionTypes.UPDATE_USER_REQUEST, req);
export const updateUserSuccess = (res) =>
    action(UserActionTypes.UPDATE_USER_SUCCESS, res);
export const updateUserFailure = (err) =>
    action(UserActionTypes.UPDATE_USER_FAILURE, err);

export const updateNomineeRequest = (req) =>
    action(UserActionTypes.UPDATE_NOMINEE_REQUEST, req);
export const updateNomineeSuccess = (res) =>
    action(UserActionTypes.UPDATE_NOMINEE_SUCCESS, res);
export const updateNomineeFailure = (err) =>
    action(UserActionTypes.UPDATE_NOMINEE_FAILURE, err);

export const fetchUserReferralRequest = (req) =>
    action(UserActionTypes.FETCH_REFERAL_REQUEST, req);
export const fetchUserReferralSuccess = (res) =>
    action(UserActionTypes.FETCH_REFERAL_SUCCESS, res);
export const fetchUserReferralFailure = (err) =>
    action(UserActionTypes.FETCH_REFERAL_FAILURE, err);

export const fetchUserRefereeRequest = (req) =>
    action(UserActionTypes.FETCH_REFEREE_REQUEST, req);
export const fetchUserRefereeSuccess = (res) =>
    action(UserActionTypes.FETCH_REFEREE_SUCCESS, res);
export const fetchUserRefereeFailure = (err) =>
    action(UserActionTypes.FETCH_REFEREE_FAILURE, err);

export const fetchUserWalletRequest = (req) =>
    action(UserActionTypes.FETCH_WALLET_REQUEST, req);
export const fetchUserWalletSuccess = (res) =>
    action(UserActionTypes.FETCH_WALLET_SUCCESS, res);
export const fetchUserWalletFailure = (err) =>
    action(UserActionTypes.FETCH_WALLET_FAILURE, err);

export const withdrawWalletRequest = (req) =>
    action(UserActionTypes.WITHDRAW_WALLET_REQUEST, req);
export const withdrawWalletSuccess = (res) =>
    action(UserActionTypes.WITHDRAW_WALLET_SUCCESS, res);
export const withdrawWalletFailure = (err) =>
    action(UserActionTypes.WITHDRAW_WALLET_FAILURE, err);

export const change2FARequest = (req) =>
    action(UserActionTypes.CHANGE_2FA_REQUEST, req);
export const change2FASuccess = (res) =>
    action(UserActionTypes.CHANGE_2FA_SUCCESS, res);
export const change2FAFailure = (err) =>
    action(UserActionTypes.CHANGE_2FA_FAILURE, err);

export const changePinRequest = (req) =>
    action(UserActionTypes.CHANGE_PIN_REQUEST, req);
export const changePinSuccess = (res) =>
    action(UserActionTypes.CHANGE_PIN_SUCCESS, res);
export const changePinFailure = (err) =>
    action(UserActionTypes.CHANGE_PIN_FAILURE, err);
