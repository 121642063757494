import { UserActionTypes } from './user.model';
require('dotenv').config();

export const initialState = {
    user: {},
    users: [],
    referals: {
        _id: '0',
        firstName: '',
        lastName: '',
        country: '',
        mobileNumber: '',
        email: '',
        memberId: '',
        referal: '',
        children: []
    },
    referees: {
        _id: '0',
        firstName: '',
        lastName: '',
        country: '',
        mobileNumber: '',
        email: '',
        memberId: '',
        referal: '',
        children: []
    },
    walletInfo: {
        coinPrice: 0,
        tokenPrice: {
            symbol: '',
            price: 0,
            name: '',
            decimals: 0,
            address: 0
        },
        tokenBalance: 0,
        coinBalance: 0
    },
    isLoading: false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case UserActionTypes.REGISTER_USER_REQUEST:
        case UserActionTypes.UPDATE_USER_REQUEST:
        case UserActionTypes.CHANGE_2FA_REQUEST:
        case UserActionTypes.CHANGE_PIN_REQUEST:
        case UserActionTypes.FETCH_REFERAL_REQUEST:
        case UserActionTypes.FETCH_REFEREE_REQUEST:
        case UserActionTypes.FETCH_USER_REQUEST:
        case UserActionTypes.WITHDRAW_WALLET_REQUEST:
        case UserActionTypes.FETCH_WALLET_REQUEST:
        case UserActionTypes.FETCH_USERS_REQUEST: {
            return {
                ...state,
                isLoading: true
            };
        }

        case UserActionTypes.FETCH_WALLET_SUCCESS: {
            return {
                ...state,
                walletInfo: action.payload,
                isLoading: false
            };
        }

        case UserActionTypes.FETCH_USERS_SUCCESS: {
            return {
                ...state,
                users: action.payload,
                isLoading: false
            };
        }

        case UserActionTypes.REGISTER_USER_SUCCESS: {
            return {
                ...state,
                isLoading: false
            };
        }

        case UserActionTypes.FETCH_USER_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                user: action.payload
            };
        }

        case UserActionTypes.CHANGE_2FA_SUCCESS:
        case UserActionTypes.CHANGE_PIN_SUCCESS:
        case UserActionTypes.UPDATE_USER_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                user: action.payload
            };
        }

        case UserActionTypes.FETCH_REFERAL_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                referals:
                    action.payload && action.payload.length > 0
                        ? action.payload[0]
                        : state.referals
            };
        }

        case UserActionTypes.FETCH_REFEREE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                referees: action.payload
            };
        }

        case UserActionTypes.REGISTER_USER_FAILURE:
        case UserActionTypes.FETCH_WALLET_FAILURE:
        case UserActionTypes.WITHDRAW_WALLET_FAILURE:
        case UserActionTypes.FETCH_USERS_FAILURE:
        case UserActionTypes.UPDATE_USER_FAILURE:
        case UserActionTypes.FETCH_REFERAL_FAILURE:
        case UserActionTypes.FETCH_REFEREE_FAILURE:
        case UserActionTypes.CHANGE_2FA_FAILURE:
        case UserActionTypes.CHANGE_PIN_FAILURE:
        case UserActionTypes.FETCH_USER_FAILURE: {
            return {
                ...state,
                isLoading: false
            };
        }

        default: {
            return state;
        }
    }
};

export { reducer as UserReducer };
