import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Container,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    RadioGroup
} from '@material-ui/core';
import {
    AccountBalanceWallet,
    ArrowDownward,
    ArrowUpward,
    BrandingWatermarkSharp,
    Check,
    Close,
    Description,
    Money,
    PersonAdd,
    PieChart,
    Receipt,
    ThumbUp,
    VerticalAlignBottom,
    VerticalAlignTop,
    WifiTethering
} from '@material-ui/icons';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPlanMemberRequest } from '../../store/plan/plan.actions';
import { getPlanMembers } from '../../store/plan/plan.selector';
import {
    fetchPayoutRequest,
    processPayoutRequest
} from '../../store/transaction/transaction.actions';
import {
    getPayoutRequests,
    isTransactionLoading
} from '../../store/transaction/transaction.selector';
import {
    fetchUserRequest,
    fetchUserWalletRequest
} from '../../store/user/user.actions';
import UserAPI from '../../store/user/user.api';
import {
    getCurrentUser,
    getWalletInformation
} from '../../store/user/user.selector';
import Loader from '../base/loader';
import TitleBar from '../base/TitleBar';
import VerifyPin from '../verify/verifyPin';

export default function DashboardContainer() {
    const dispatch = useDispatch();
    const [stats, setStats] = useState({});
    const [acknowledge, setAcknowledge] = useState(false);
    const [value, setValue] = React.useState('Own');
    const [type, setType] = React.useState('USDT');

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const { walletInfo, transactions, currentUser, isLoading, planMembers } =
        useSelector((state) => {
            return {
                walletInfo: getWalletInformation(state),
                transactions: getPayoutRequests(state),
                currentUser: getCurrentUser(state),
                isLoading: isTransactionLoading(state),
                planMembers: getPlanMembers(state)
            };
        });

    useEffect(() => {
        dispatch(fetchUserRequest());
        dispatch(fetchPlanMemberRequest());
        dispatch(fetchPayoutRequest());
        UserAPI.GetStats().then((s) => {
            setStats(s);
        });
    }, []);

    useEffect(() => {
        if (walletInfo.coinPrice == 0) {
            dispatch(fetchUserWalletRequest());
        }
    }, [walletInfo]);

    const netPayout = transactions
        .filter((t) => t.status === 'Created')
        .map((t) => t.amount)
        .reduce((partialSum, a) => partialSum + a, 0);

    let balanceAmount = netPayout;

    let maxPayout = planMembers
        .filter((x) => x.status === 'Approved')
        .map((d) => d.accumulatedAmount)
        .reduce((partialSum, a) => partialSum + a, 0);

    let netBNB = 0;

    let payoutIds = [];
    let payableAmount = maxPayout;
    let eligibleAmount = 0;
    transactions
        .filter((t) => t.status === 'Created')
        .some((s) => {
            if (s.amount < payableAmount && payableAmount > 0) {
                payableAmount = payableAmount - s.amount;
                payoutIds.push(s._id);
                eligibleAmount = eligibleAmount + s.amount;
            } else {
                return false;
            }
        });
    maxPayout = Math.ceil(maxPayout);

    if (netPayout >= maxPayout) {
        netBNB = maxPayout / walletInfo.coinPrice;
    } else {
        netBNB = netPayout / walletInfo.coinPrice;
    }

    const orgPaidAmount = planMembers
        .map((d) => d.amount * 5)
        .reduce((partialSum, a) => partialSum + a, 0);

    let netPaidAmount =
        orgPaidAmount -
        planMembers
            .map((d) => d.accumulatedAmount)
            .reduce((partialSum, a) => partialSum + a, 0);

    netPaidAmount = isNaN(netPaidAmount) ? 0 : netPaidAmount;
    return (
        <div>
            {isLoading && <Loader />}
            <TitleBar
                heading="Dashboard"
                secondary={''}
                icon={
                    <HelpOutlineIcon fontSize="large" htmlColor="goldenrod" />
                }
            />
            <Dialog open={acknowledge} maxWidth="sm" fullWidth>
                <DialogTitle>
                    Payout Confirmation
                    <Close
                        onClick={() => setAcknowledge(false)}
                        className="float-right"
                    />
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">
                            {' '}
                            Withdraw Payout to
                        </FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="radio-buttons-group"
                            value={value}
                            onChange={handleChange}
                        >
                            <FormControlLabel
                                value={'Own'}
                                control={<Radio />}
                                label={
                                    'Own Wallet (' +
                                    currentUser.walletAddress +
                                    ')'
                                }
                            />
                            <FormControlLabel
                                value="Whitelist"
                                disabled={!currentUser.withdrawAddress}
                                control={<Radio />}
                                label={
                                    'Whitelist Wallet (' +
                                    (currentUser.withdrawAddress ||
                                        'Not Available') +
                                    ')'
                                }
                            />
                        </RadioGroup>
                    </FormControl>
                    <FormControl className="my-2">
                        <FormLabel id="demo-radio-buttons-group-label">
                            {' '}
                            Withdraw Payout as
                        </FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="radio-buttons-group"
                            value={type}
                            onChange={(e) => setType(e.target.value)}
                        >
                            <FormControlLabel
                                value="USDT"
                                control={<Radio />}
                                label={`USDT (${
                                    netPayout >= maxPayout
                                        ? maxPayout.toFixed(3)
                                        : netPayout.toFixed(3)
                                } USDT)`}
                            />
                            <FormControlLabel
                                value={'BNB'}
                                control={<Radio />}
                                disabled
                                label={`BNB (${netBNB.toFixed(8)} BNB)`}
                            />
                        </RadioGroup>
                    </FormControl>
                    <Divider />
                    <VerifyPin
                        id={currentUser.id}
                        onClose={() => {
                            setAcknowledge(false);
                        }}
                        isNotVerify={true}
                        onComplete={(code) => {
                            dispatch(
                                processPayoutRequest({
                                    type: value,
                                    method: type,
                                    code: code
                                })
                            );
                            setAcknowledge(false);
                        }}
                    />
                </DialogContent>
            </Dialog>
            <Container maxWidth="xl">
                <Card>
                    <CardHeader
                        title={'Your Rewards'}
                        subheader={currentUser.memberId}
                    />
                    <Divider />
                    <CardContent>
                        <Grid container className="mt-2" spacing={2}>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <Card elevation={10}>
                                    <CardHeader
                                        titleTypographyProps={{
                                            style: {
                                                fontSize: 18,
                                                fontWeight: 700
                                            },
                                            className: 'text-primary'
                                        }}
                                        avatar={
                                            <PersonAdd
                                                color="secondary"
                                                fontSize="large"
                                                style={{ color: 'goldenrod' }}
                                            />
                                        }
                                        title={
                                            '$ ' +
                                            transactions
                                                .filter(
                                                    (t) =>
                                                        t.method ===
                                                            'Referral' &&
                                                        t.status !==
                                                            'InEligible'
                                                )
                                                .map((t) => t.amount)
                                                .reduce(
                                                    (partialSum, a) =>
                                                        partialSum + a,
                                                    0
                                                )
                                                .toFixed(3)
                                        }
                                        subheader="Referral Reward"
                                    />
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <Card elevation={10}>
                                    <CardHeader
                                        titleTypographyProps={{
                                            style: {
                                                fontSize: 18,
                                                fontWeight: 700
                                            },
                                            className: 'text-secondary'
                                        }}
                                        avatar={
                                            <BrandingWatermarkSharp
                                                color="secondary"
                                                fontSize="large"
                                                style={{ color: 'goldenrod' }}
                                            />
                                        }
                                        title={
                                            '$ ' +
                                            transactions
                                                .filter(
                                                    (t) =>
                                                        t.method === 'Reward' &&
                                                        t.status !==
                                                            'InEligible'
                                                )
                                                .map((t) => t.amount)
                                                .reduce(
                                                    (partialSum, a) =>
                                                        partialSum + a,
                                                    0
                                                )
                                                .toFixed(3)
                                        }
                                        subheader="Plan Reward"
                                    />
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <Card elevation={10}>
                                    <CardHeader
                                        titleTypographyProps={{
                                            style: {
                                                fontSize: 18,
                                                fontWeight: 700
                                            }
                                        }}
                                        avatar={
                                            <PieChart
                                                color="secondary"
                                                fontSize="large"
                                                style={{ color: 'goldenrod' }}
                                            />
                                        }
                                        title={
                                            '$ ' +
                                            transactions
                                                .filter(
                                                    (t) =>
                                                        t.status !==
                                                        'InEligible'
                                                )
                                                .map((t) => t.amount)
                                                .reduce(
                                                    (partialSum, a) =>
                                                        partialSum + a,
                                                    0
                                                )
                                                .toFixed(3)
                                        }
                                        subheader="Total Reward"
                                    />
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <Card elevation={10}>
                                    <CardHeader
                                        titleTypographyProps={{
                                            style: {
                                                fontSize: 18,
                                                fontWeight: 700
                                            },
                                            className: 'text-success'
                                        }}
                                        avatar={
                                            <Check
                                                color="secondary"
                                                fontSize="large"
                                                style={{ color: 'goldenrod' }}
                                            />
                                        }
                                        title={'$ ' + netPaidAmount}
                                        subheader="Reward Paid (Rounded)"
                                    />
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <Card elevation={10}>
                                    <CardHeader
                                        titleTypographyProps={{
                                            style: {
                                                fontSize: 18,
                                                fontWeight: 700
                                            },
                                            className: 'text-warning'
                                        }}
                                        avatar={
                                            <AccountBalanceWallet
                                                color="secondary"
                                                fontSize="large"
                                                style={{ color: 'goldenrod' }}
                                            />
                                        }
                                        title={
                                            '$ ' +
                                            transactions
                                                .filter(
                                                    (t) =>
                                                        t.status === 'Created'
                                                )
                                                .map((t) => t.amount)
                                                .reduce(
                                                    (partialSum, a) =>
                                                        partialSum + a,
                                                    0
                                                )
                                                .toFixed(3)
                                        }
                                        subheader="Reward Balance"
                                    />
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <Card elevation={10}>
                                    <CardHeader
                                        titleTypographyProps={{
                                            style: {
                                                fontSize: 18,
                                                fontWeight: 700
                                            },
                                            className: 'text-info'
                                        }}
                                        avatar={
                                            <ThumbUp
                                                color="secondary"
                                                fontSize="large"
                                                style={{ color: 'goldenrod' }}
                                            />
                                        }
                                        title={'$ ' + maxPayout.toFixed(3)}
                                        subheader="Reward Eligible"
                                    />
                                </Card>
                            </Grid>
                        </Grid>
                        <div className="mt-4">
                            ***Above calculation is based on only Eligible
                            Payouts.
                        </div>
                        {maxPayout > 0 && (
                            <>
                                <h6 className="mt-4">Your Active Plans</h6>
                                <table className="table table-striped mt-2">
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Joined Date</th>
                                            <th>Amount</th>
                                            <th>Eligible Returns</th>
                                            {/* <th>After Payout</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {planMembers
                                            .filter(
                                                (s) => s.status === 'Approved'
                                            )
                                            .map((d, index) => {
                                                if (
                                                    balanceAmount <
                                                    d.accumulatedAmount
                                                ) {
                                                    balanceAmount =
                                                        d.accumulatedAmount -
                                                        balanceAmount;
                                                    return (
                                                        <tr>
                                                            <td>{index + 1}</td>
                                                            <td>
                                                                {moment(
                                                                    d.createdAt
                                                                ).format(
                                                                    'DD-MM-YYYY'
                                                                )}
                                                            </td>
                                                            <td>
                                                                {d.amount} USD
                                                            </td>
                                                            <td>
                                                                {
                                                                    d.accumulatedAmount
                                                                }{' '}
                                                                USD
                                                            </td>
                                                            {/* <td>{d.accumulatedAmount - balanceAmount} USD (Active)</td> */}
                                                        </tr>
                                                    );
                                                } else {
                                                    balanceAmount =
                                                        balanceAmount -
                                                        d.accumulatedAmount;
                                                    return (
                                                        <tr>
                                                            <td>{index + 1}</td>
                                                            <td>
                                                                {moment(
                                                                    d.createdAt
                                                                ).format(
                                                                    'DD-MM-YYYY'
                                                                )}
                                                            </td>
                                                            <td>
                                                                {d.amount} USD
                                                            </td>
                                                            <td>
                                                                {
                                                                    d.accumulatedAmount
                                                                }{' '}
                                                                USD
                                                            </td>
                                                            {/* <td>{"0"} USD (In-active)</td> */}
                                                        </tr>
                                                    );
                                                }
                                            })}
                                    </tbody>
                                </table>
                                <p className="text-danger">
                                    You can withdraw maximum of{' '}
                                    <span className="text-large">
                                        {netPayout >= maxPayout
                                            ? maxPayout.toFixed(3)
                                            : netPayout.toFixed(3)}
                                    </span>{' '}
                                    USD.
                                </p>
                            </>
                        )}
                    </CardContent>
                    <CardActions>
                        <Box
                            display={'flex'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                            width={'100%'}
                        >
                            <div>
                                <Button href="/payouts">
                                    View All Payouts
                                </Button>
                            </div>
                            <Button
                                variant="contained"
                                onClick={() => setAcknowledge(true)}
                                color="primary"
                                disabled={parseFloat(netPayout) < 10}
                            >
                                Withdraw
                            </Button>
                        </Box>
                    </CardActions>
                </Card>
            </Container>
            <Grid container className="mt-2" spacing={2}>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Card elevation={10}>
                        <CardHeader
                            avatar={
                                <AccountBalanceWallet
                                    color="secondary"
                                    fontSize="large"
                                    style={{ color: 'goldenrod' }}
                                />
                            }
                            title={walletInfo.coinBalance + ' BNB'}
                            subheader="Coin Balance"
                        />
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Card elevation={10}>
                        <CardHeader
                            avatar={
                                <Money
                                    color="secondary"
                                    fontSize="large"
                                    style={{ color: 'goldenrod' }}
                                />
                            }
                            title={
                                walletInfo.tokenBalance +
                                ' ' +
                                walletInfo.tokenPrice.symbol
                            }
                            subheader={'Token Balance'}
                        />
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Card elevation={10}>
                        <CardHeader
                            avatar={
                                <VerticalAlignTop
                                    color="secondary"
                                    fontSize="large"
                                    style={{ color: 'goldenrod' }}
                                />
                            }
                            title={stats.buyTransactionCount}
                            subheader={'Buy Transactions'}
                        />
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Card elevation={10}>
                        <CardHeader
                            avatar={
                                <VerticalAlignBottom
                                    color="secondary"
                                    fontSize="large"
                                    style={{ color: 'goldenrod' }}
                                />
                            }
                            title={stats.sellTransactionCount}
                            subheader={'Sell Transactions'}
                        />
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Card elevation={10}>
                        <CardHeader
                            avatar={
                                <ArrowDownward
                                    color="secondary"
                                    fontSize="large"
                                    style={{ color: 'goldenrod' }}
                                />
                            }
                            title={
                                stats.totalCoinsSold &&
                                stats.totalCoinsSold.$numberDecimal + ' BNB'
                            }
                            subheader={'Coins Spent'}
                        />
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Card elevation={10}>
                        <CardHeader
                            avatar={
                                <ArrowUpward
                                    color="secondary"
                                    fontSize="large"
                                    style={{ color: 'goldenrod' }}
                                />
                            }
                            title={
                                stats.totalTokensSold &&
                                stats.totalTokensSold.$numberDecimal +
                                    ' ' +
                                    walletInfo.tokenPrice.symbol
                            }
                            subheader={'Tokens Received'}
                        />
                    </Card>
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Card elevation={10}>
                        <CardHeader
                            avatar={
                                <WifiTethering
                                    color="secondary"
                                    fontSize="large"
                                    style={{ color: 'goldenrod' }}
                                />
                            }
                            title={stats.totalWithdrawCount}
                            subheader={'Total Withdraws'}
                        />
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Card elevation={10}>
                        <CardHeader
                            avatar={
                                <Receipt
                                    color="secondary"
                                    fontSize="large"
                                    style={{ color: 'goldenrod' }}
                                />
                            }
                            title={
                                stats.totalTokensWithdraw +
                                ' ' +
                                walletInfo.tokenPrice.symbol
                            }
                            subheader={'Tokens Withdrawn'}
                        />
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Card elevation={10}>
                        <CardHeader
                            avatar={
                                <Description
                                    color="secondary"
                                    fontSize="large"
                                    style={{ color: 'goldenrod' }}
                                />
                            }
                            title={stats.totalCoinsWithdraw + ' BNB'}
                            subheader={'Coins Withdrawn'}
                        />
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}
