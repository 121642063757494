import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    TextField
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { IsNullOrEmpty, clearToken } from '../../helpers/utility';
import UserAPI from '../../store/user/user.api';

function ChangePassword(props) {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [cpassword, setCPassword] = useState('');
    const [isValidated, setValidated] = useState(false);

    const validate = () => {
        return (
            IsNullOrEmpty(oldPassword) ||
            IsNullOrEmpty(newPassword) ||
            IsNullOrEmpty(cpassword) ||
            newPassword !== cpassword
        );
    };

    const handleChangePassword = (e) => {
        e.preventDefault();
        const isNotValid = validate();
        if (isNotValid) {
            setValidated(true);
        } else {
            UserAPI.ChangePassword({
                oldPassword: oldPassword,
                newPassword: newPassword
            }).then((s) => {
                Swal.fire({
                    icon: 'success',
                    title: 'Password Update',
                    text: 'Please login again',
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonText: 'Close'
                }).then((x) => {
                    if (x.isDismissed) {
                        clearToken();
                    }
                });
            });
        }
    };

    if (props.canShow) {
        return (
            <>
                <form onSubmit={(e) => handleChangePassword(e)}>
                    <div className="form-group">
                        <TextField
                            label="Old Password"
                            placeholder="Old Password"
                            variant="outlined"
                            type="password"
                            color="primary"
                            fullWidth
                            size="small"
                            value={oldPassword}
                            onChange={(e) => setOldPassword(e.target.value)}
                            helperText={
                                isValidated && IsNullOrEmpty(oldPassword)
                                    ? 'Please enter old password'
                                    : ''
                            }
                            error={isValidated && IsNullOrEmpty(oldPassword)}
                        />
                    </div>
                    <div className="form-group">
                        <TextField
                            label="New Password"
                            placeholder="New Password"
                            variant="outlined"
                            type="password"
                            color="primary"
                            fullWidth
                            size="small"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            helperText={
                                isValidated && IsNullOrEmpty(newPassword)
                                    ? 'Please enter password'
                                    : isValidated && newPassword !== cpassword
                                    ? 'Password should match'
                                    : ''
                            }
                            error={
                                isValidated &&
                                (IsNullOrEmpty(newPassword) ||
                                    newPassword !== cpassword)
                            }
                        />
                    </div>
                    <div className="form-group">
                        <TextField
                            label="Confirm Password"
                            placeholder="Confirm Password"
                            type="password"
                            variant="outlined"
                            color="primary"
                            fullWidth
                            size="small"
                            value={cpassword}
                            onChange={(e) => setCPassword(e.target.value)}
                            helperText={
                                isValidated && IsNullOrEmpty(cpassword)
                                    ? 'Please enter confirm password'
                                    : ''
                            }
                            error={isValidated && IsNullOrEmpty(cpassword)}
                        />
                    </div>
                    <button type="submit" className="btn btn-primary">
                        Reset Password
                    </button>
                </form>
            </>
        );
    }

    return (
        <>
            <Dialog
                maxWidth="xs"
                fullWidth
                open={true}
                onClose={() => {
                    if (props.onClose) {
                        props.onClose();
                    }
                }}
            >
                <DialogTitle>
                    Change Password
                    {props.onClose && (
                        <Close
                            className="float-right"
                            onClick={() => props.onClose()}
                        />
                    )}
                </DialogTitle>
                <DialogContent>
                    <form onSubmit={(e) => handleChangePassword(e)}>
                        <div className="form-group">
                            <TextField
                                label="Old Password"
                                placeholder="Old Password"
                                variant="outlined"
                                type="password"
                                color="primary"
                                fullWidth
                                size="small"
                                value={oldPassword}
                                onChange={(e) => setOldPassword(e.target.value)}
                                helperText={
                                    isValidated && IsNullOrEmpty(oldPassword)
                                        ? 'Please enter old password'
                                        : ''
                                }
                                error={
                                    isValidated && IsNullOrEmpty(oldPassword)
                                }
                            />
                        </div>
                        <div className="form-group">
                            <TextField
                                label="New Password"
                                placeholder="New Password"
                                variant="outlined"
                                type="password"
                                color="primary"
                                fullWidth
                                size="small"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                helperText={
                                    isValidated && IsNullOrEmpty(newPassword)
                                        ? 'Please enter password'
                                        : isValidated &&
                                          newPassword !== cpassword
                                        ? 'Password should match'
                                        : ''
                                }
                                error={
                                    isValidated &&
                                    (IsNullOrEmpty(newPassword) ||
                                        newPassword !== cpassword)
                                }
                            />
                        </div>
                        <div className="form-group">
                            <TextField
                                label="Confirm Password"
                                placeholder="Confirm Password"
                                type="password"
                                variant="outlined"
                                color="primary"
                                fullWidth
                                size="small"
                                value={cpassword}
                                onChange={(e) => setCPassword(e.target.value)}
                                helperText={
                                    isValidated && IsNullOrEmpty(cpassword)
                                        ? 'Please enter confirm password'
                                        : ''
                                }
                                error={isValidated && IsNullOrEmpty(cpassword)}
                            />
                        </div>
                        <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            fullWidth
                        >
                            Reset Password
                        </Button>
                    </form>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default ChangePassword;
