import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Container,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    Grid,
    InputAdornment,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    MenuItem,
    Paper,
    Select,
    Tab,
    Tabs,
    TextField
} from '@material-ui/core';
import {
    AccountBalanceWallet,
    AccountBoxSharp,
    AccountCircle,
    CalendarToday,
    Close,
    Email,
    FileCopy,
    Fingerprint,
    Link,
    Lock,
    PersonAdd,
    PhoneAndroid,
    Replay
} from '@material-ui/icons';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import OTPInput from 'react-otp-input';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as Toastr from 'toastr';
import { IsNullOrEmpty } from '../../helpers/utility';
import {
    createBuyRequest,
    createSellRequest,
    createWithdrawRequest
} from '../../store/transaction/transaction.actions';
import {
    change2FARequest,
    changePinRequest,
    fetchUserRequest,
    fetchUserWalletRequest,
    updateNomineeRequest,
    withdrawWalletRequest
} from '../../store/user/user.actions';
import UserAPI from '../../store/user/user.api';
import {
    getCurrentUser,
    getWalletInformation
} from '../../store/user/user.selector';
import Loader from '../base/loader';
import TitleBar from '../base/TitleBar';
import VerifyPin from '../verify/verifyPin';
import ChangePassword from './changePassword';
import BuyRequest from './operations/Buy.Request';
import DepositRequest from './operations/Deposit.Request';
import SellRequest from './operations/Sell.Request';
import WithdrawRequest from './operations/Withdraw.Request';

export default function ProfileContainer() {
    const dispatch = useDispatch();
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const [acknowledge, setAcknowledge] = useState(false);
    const [mode, setMode] = useState('');
    const [address, setAddress] = useState('');
    const [minCoins, setMinCoins] = useState(0.1);
    const [maxCoins, setMaxCoins] = useState(1);
    const [coolingPeriod, setCoolingPeriod] = useState(-1);
    const [withdrawMode, setWithdrawMode] = useState('USDT');
    const [isChange, setChange] = useState(false);
    const [transferAmount, setTransferAmount] = useState(0);
    const [transferCoins, setTransferCoins] = useState(0);
    const [transferTokens, setTransferTokens] = useState(0);
    const [show2FA, set2FA] = useState(false);
    const [forgot2FA, setForgot2FA] = useState(false);
    const [QRCode, SetQRCode] = useState('');
    const [QRCodePin, SetQRCodePin] = useState('');

    const [pin, setPIN] = useState('');
    const [cPin, setCPin] = useState('');
    const [value, setValue] = React.useState(0);

    const [nomineeName, setNomineeName] = useState('');
    const [nomineeDOB, setNomineeDOB] = useState('');
    const [nomineeRelation, setNomineeRelation] = useState('');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        dispatch(fetchUserRequest());
    }, []);

    const { currentUser, walletInfo, isLoading } = useSelector((state) => {
        return {
            currentUser: getCurrentUser(state),
            walletInfo: getWalletInformation(state),
            isLoading:
                state.UserReducer.isLoading ||
                state.TransactionReducer.isLoading
        };
    }, []);

    const onUpdateNominee = () => {
        if (IsNullOrEmpty(nomineeName)) {
            Swal.fire({
                title: 'Nominee Name',
                text: 'Nominee Name Should be Valid',
                icon: 'error'
            });
        } else if (IsNullOrEmpty(nomineeDOB)) {
            Swal.fire({
                title: 'Nominee DOB',
                text: 'Nominee DOB Should be Valid',
                icon: 'error'
            });
        } else if (moment().diff(moment(nomineeDOB), 'year') < 18) {
            Swal.fire({
                title: 'Nominee DOB',
                text: 'Nominee Age should be atleast 18 years old',
                icon: 'error'
            });
        } else if (IsNullOrEmpty(nomineeRelation)) {
            Swal.fire({
                title: 'Nominee Relationship',
                text: 'Nominee Relationship Should be Valid',
                icon: 'error'
            });
        } else {
            dispatch(
                updateNomineeRequest({
                    nomineeName,
                    nomineeDOB,
                    nomineeRelation
                })
            );
        }
    };

    useEffect(() => {
        setOpen(false);
        setAcknowledge(false);
        setMode('');
        SetQRCode('');
        SetQRCodePin('');
        set2FA(false);
    }, [currentUser, walletInfo]);

    useEffect(() => {
        if (currentUser.nomineeName) {
            setNomineeName(currentUser.nomineeName);
            setNomineeDOB(currentUser.nomineeDOB);
            setNomineeRelation(currentUser.nomineeRelation);
        }
    }, [currentUser]);

    let oneBNBPriceInUSD = parseFloat(walletInfo.coinPrice);
    let oneUSDPriceInBNB = 1 / parseFloat(walletInfo.coinPrice);
    let oneTokenPriceInUSD = parseFloat(walletInfo.tokenPrice.price);
    let transacationFeesCoins = (0.2 / oneBNBPriceInUSD).toFixed(8);
    let transacationFeesToken = (
        (2 * walletInfo.usdtTokenBalance) /
        100
    ).toFixed(3);
    let bal = parseFloat(walletInfo.coinBalance) - transacationFeesCoins;
    let totalUSD = parseFloat(bal) * oneBNBPriceInUSD;
    let coinDollar = parseFloat(walletInfo.coinBalance) * walletInfo.coinPrice;
    coinDollar = parseFloat(coinDollar.toFixed(3));
    return (
        <div>
            {isLoading && <Loader />}
            <Dialog
                open={forgot2FA}
                maxWidth="xs"
                fullWidth
                onClose={() => setForgot2FA(false)}
            >
                <DialogTitle>
                    Forgot 2FA
                    <Close
                        onClick={() => setForgot2FA(false)}
                        className="float-right"
                    />
                </DialogTitle>
                <DialogContent>
                    <Box>
                        <h5 className="text-center my-2 text-secondary">
                            Scan the QR Code from your authenticator App
                        </h5>
                        <p className="text-center mb-4">
                            <img src={QRCode} alt="QR Code" />
                        </p>
                        <TextField
                            label="Secret"
                            margin="dense"
                            variant="outlined"
                            value={QRCodePin}
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment
                                        position="end"
                                        onClick={() => {
                                            navigator.clipboard.writeText(
                                                QRCodePin
                                            );
                                            Toastr.info('copied to clipboard');
                                        }}
                                    >
                                        <FileCopy />
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Box>
                </DialogContent>
            </Dialog>
            <Dialog open={show2FA} maxWidth="xs" fullWidth>
                <DialogTitle>Change to 2FA Mode</DialogTitle>
                <Divider />
                <DialogContent>
                    {currentUser.pinType !== 'PIN' ? (
                        <Box
                            display={'flex'}
                            alignItems={'center'}
                            flexDirection={'column'}
                            justifyContent={'center'}
                        >
                            <label className="my-2">PIN</label>
                            <OTPInput
                                value={pin}
                                inputStyle="otp-input"
                                onChange={setPIN}
                                inputType="password"
                                numInputs={6}
                                renderSeparator={<span className="mr-1"></span>}
                                renderInput={(props) => <input {...props} />}
                            />
                            <label className="my-2">Confirm PIN</label>
                            <OTPInput
                                value={cPin}
                                inputStyle="otp-input"
                                onChange={setCPin}
                                inputType="password"
                                numInputs={6}
                                renderSeparator={<span className="mr-1"></span>}
                                renderInput={(props) => <input {...props} />}
                            />
                            <Button
                                onClick={() => {
                                    setMode('changePIN');
                                    setAcknowledge(true);
                                }}
                                className="my-2"
                                variant="contained"
                                color={'primary'}
                                disabled={
                                    IsNullOrEmpty(pin) ||
                                    IsNullOrEmpty(cPin) ||
                                    pin !== cPin ||
                                    pin.length !== 6 ||
                                    cPin.length !== 6
                                }
                            >
                                Change Now
                            </Button>
                        </Box>
                    ) : (
                        <Box>
                            <h5 className="text-center my-2 text-secondary">
                                Scan the QR Code from your authenticator App
                            </h5>
                            <p className="text-center mb-4">
                                <img src={QRCode} alt="QR Code" />
                            </p>
                            <TextField
                                label="Secret"
                                margin="dense"
                                variant="outlined"
                                value={QRCodePin}
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment
                                            position="end"
                                            onClick={() => {
                                                navigator.clipboard.writeText(
                                                    QRCodePin
                                                );
                                                Toastr.info(
                                                    'copied to clipboard'
                                                );
                                            }}
                                        >
                                            <FileCopy />
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <VerifyPin
                                isNotVerify
                                text={'Enter 2FA Code'}
                                onComplete={(code) => {
                                    dispatch(change2FARequest(code));
                                }}
                                onClose={() => {
                                    setMode('');
                                    setAcknowledge(false);
                                    SetQRCode('');
                                    SetQRCodePin('');
                                    set2FA(false);
                                }}
                            />
                        </Box>
                    )}
                </DialogContent>
            </Dialog>
            {isChange && <ChangePassword onClose={() => setChange(false)} />}
            <Dialog open={acknowledge} maxWidth="sm" fullWidth>
                <DialogContent>
                    <VerifyPin
                        text={`Enter ${
                            currentUser.pinType === 'PIN'
                                ? 'User PIN'
                                : '2FA Code'
                        }`}
                        id={currentUser.id}
                        onClose={() => {
                            setAcknowledge(false);
                        }}
                        isNotVerify={true}
                        onComplete={(code) => {
                            if (mode === 'Whitelist') {
                                dispatch(
                                    withdrawWalletRequest({
                                        code: code,
                                        data: {
                                            address: address,
                                            minCoins: minCoins,
                                            maxCoins: maxCoins,
                                            coolingPeriod: !coolingPeriod
                                                ? -1
                                                : coolingPeriod,
                                            type: 'BNB'
                                        }
                                    })
                                );
                            } else if (mode === 'Buy') {
                                dispatch(
                                    createBuyRequest({
                                        code: code,
                                        data: {
                                            transactionCoins: transferCoins,
                                            transactionTokens: transferTokens,
                                            transactionMode: withdrawMode,
                                            swapType: 'Buy'
                                        }
                                    })
                                );
                            } else if (mode === 'Sell') {
                                dispatch(
                                    createSellRequest({
                                        code: code,
                                        data: {
                                            transactionCoins: transferCoins,
                                            transactionTokens: transferTokens,
                                            transactionMode: withdrawMode,
                                            swapType: 'Sell'
                                        }
                                    })
                                );
                            } else if (mode === 'Withdraw') {
                                dispatch(
                                    createWithdrawRequest({
                                        code: code,
                                        data: {
                                            amount: transferCoins,
                                            isToken: withdrawMode !== 'BNB',
                                            tokenAddress:
                                                withdrawMode === 'BNB'
                                                    ? ''
                                                    : withdrawMode === 'USDT'
                                                    ? '0x55d398326f99059fF775485246999027B3197955'
                                                    : '0x268F515FbA07Dc372e225374Ba0bc636Dc458583'
                                        }
                                    })
                                );
                            } else if (mode === 'Change2FA') {
                                dispatch(change2FARequest(code));
                            } else if (mode === 'changePIN') {
                                dispatch(
                                    changePinRequest({
                                        code: code,
                                        pin: pin
                                    })
                                );
                            }
                            setAcknowledge(false);
                        }}
                    />
                </DialogContent>
            </Dialog>

            <Dialog
                open={open}
                maxWidth="sm"
                fullWidth
                onClose={() => setOpen(false)}
            >
                <DialogTitle>
                    {mode === 'Deposit'
                        ? 'Scan QR Code'
                        : mode === 'Whitelist'
                        ? 'Withdraw Wallet'
                        : mode === 'Buy'
                        ? 'Buy Tokens'
                        : mode === 'Sell'
                        ? 'Sell Tokens'
                        : mode === 'Withdraw'
                        ? 'Withdraw Request'
                        : ''}
                    <Close
                        className="float-right"
                        onClick={() => {
                            setMode('');
                            setOpen(false);
                        }}
                    />
                </DialogTitle>
                <Divider />
                <DialogContent>
                    {mode === 'Deposit' ? (
                        <DepositRequest />
                    ) : mode === 'Whitelist' ? (
                        <Box>
                            <TextField
                                fullWidth
                                className="my-1"
                                margin="dense"
                                variant="outlined"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                                placeholder={`Enter BNB/BEP20/USDT/${process.env.REACT_APP_TOKEN_SYMBOL} Address`}
                                label={`BNB/BEP20/USDT/${process.env.REACT_APP_TOKEN_SYMBOL} Address`}
                            />
                            <TextField
                                fullWidth
                                margin="dense"
                                className="my-1"
                                variant="outlined"
                                value={minCoins}
                                type="phone"
                                onChange={(e) => setMinCoins(e.target.value)}
                                placeholder="Min Coins"
                                label="Min Coins"
                            />
                            <TextField
                                fullWidth
                                margin="dense"
                                className="my-1"
                                variant="outlined"
                                value={maxCoins}
                                type="phone"
                                onChange={(e) => setMaxCoins(e.target.value)}
                                placeholder="Max Coins"
                                label="Max Coins"
                            />
                            <TextField
                                fullWidth
                                margin="dense"
                                className="my-1"
                                variant="outlined"
                                value={coolingPeriod}
                                type="phone"
                                onChange={(e) =>
                                    setCoolingPeriod(e.target.value)
                                }
                                placeholder="Cooling Period"
                                label="Cooling Period"
                                helperText="-1 means immediate transfer"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            Minutes
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <Button
                                variant="contained"
                                className="float-right"
                                onClick={() => {
                                    Swal.fire({
                                        icon: 'question',
                                        title: 'Withdraw Wallet',
                                        text: 'Please verify all the information. This cannot be changed later',
                                        showCancelButton: true,
                                        showConfirmButton: true,
                                        confirmButtonText: 'Yes, Proceed',
                                        cancelButtonText: 'Close'
                                    }).then((s) => {
                                        if (s.isConfirmed) {
                                            setAcknowledge(true);
                                        }
                                    });
                                }}
                                color="primary"
                                disabled={
                                    IsNullOrEmpty(address) ||
                                    IsNullOrEmpty(minCoins) ||
                                    IsNullOrEmpty(maxCoins) ||
                                    parseFloat(minCoins) === 0 ||
                                    parseFloat(maxCoins) === 0 ||
                                    parseFloat(minCoins) >
                                        parseFloat(maxCoins) ||
                                    isLoading
                                }
                            >
                                Add Address
                            </Button>
                        </Box>
                    ) : mode === 'Buy' ? (
                        <BuyRequest
                            onBuy={(mode, transferAmount, transferTokens) => {
                                setWithdrawMode(mode);
                                setTransferCoins(transferAmount);
                                setTransferTokens(transferTokens);
                                setAcknowledge(true);
                            }}
                        />
                    ) : mode === 'Sell' ? (
                        <>
                            <SellRequest
                                onSell={(
                                    mode,
                                    transferAmount,
                                    transferTokens
                                ) => {
                                    setWithdrawMode(mode);
                                    setTransferCoins(transferAmount);
                                    setTransferTokens(transferTokens);
                                    setAcknowledge(true);
                                }}
                            />
                        </>
                    ) : mode === 'Withdraw' ? (
                        <WithdrawRequest
                            onWithdraw={(mode, transferAmount) => {
                                setWithdrawMode(mode);
                                setTransferCoins(transferAmount);
                                setAcknowledge(true);
                            }}
                        />
                    ) : (
                        <></>
                    )}
                </DialogContent>
            </Dialog>
            <TitleBar
                heading="Profile Information"
                secondary={'Profile Information'}
                icon={<AccountCircle fontSize="large" htmlColor="goldenrod" />}
            />
            <Grid container spacing={2} className="content-height">
                <Grid item md={12} lg={12} sm={12} xs={12}>
                    <Paper square className="w-100">
                        <Tabs
                            value={value}
                            variant="fullWidth"
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={handleChange}
                        >
                            <Tab label="Wallets" />
                            <Tab label="Profile" />
                            <Tab label="Security" />
                        </Tabs>
                    </Paper>
                    {value === 0 ? (
                        <Grid container spacing={2}>
                            <Grid item md={6} lg={6} sm={12} xs={12}>
                                <Card
                                    className="mt-2"
                                    elevation={4}
                                    style={{ background: 'transparent' }}
                                >
                                    <CardHeader
                                        avatar={
                                            <Avatar
                                                style={{
                                                    background: 'transparent'
                                                }}
                                            >
                                                <img
                                                    src={
                                                        process.env.PUBLIC_URL +
                                                        'BNB.png'
                                                    }
                                                    width="100%"
                                                />
                                            </Avatar>
                                        }
                                        title={'BNB Wallet'}
                                        subheader="Secured by Bicoince Wallet"
                                        action={
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    'splash.png'
                                                }
                                                height={48}
                                                className="mr-2"
                                            />
                                        }
                                    />
                                    <CardContent>
                                        <Box
                                            display={'flex'}
                                            flexDirection={'column'}
                                            alignItems={'center'}
                                        >
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    'BNB.png'
                                                }
                                                width={120}
                                            />
                                            <h6
                                                className="my-2 overflow-text"
                                                onClick={() => {
                                                    navigator.clipboard.writeText(
                                                        currentUser.walletAddress
                                                    );
                                                    Toastr.info(
                                                        'Address copied to clipboard'
                                                    );
                                                }}
                                            >
                                                {currentUser.walletAddress}
                                            </h6>
                                            {walletInfo.coinPrice === 0 ? (
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    disabled={isLoading}
                                                    onClick={() =>
                                                        dispatch(
                                                            fetchUserWalletRequest()
                                                        )
                                                    }
                                                >
                                                    Get Wallet Info
                                                </Button>
                                            ) : (
                                                <>
                                                    <h4 className="text-success text-bold">
                                                        {
                                                            walletInfo.usdtTokenBalance
                                                        }{' '}
                                                        USDT{' '}
                                                        <Replay
                                                            className="cursor"
                                                            onClick={() =>
                                                                dispatch(
                                                                    fetchUserWalletRequest()
                                                                )
                                                            }
                                                        />
                                                    </h4>
                                                    <h4 className="text-primary text-bold">
                                                        {walletInfo.coinBalance}{' '}
                                                        BNB{' '}
                                                        <Replay
                                                            className="cursor"
                                                            onClick={() =>
                                                                dispatch(
                                                                    fetchUserWalletRequest()
                                                                )
                                                            }
                                                        />
                                                    </h4>
                                                    <h4 className="text-secondary">
                                                        {
                                                            walletInfo.tokenBalance
                                                        }{' '}
                                                        {
                                                            walletInfo
                                                                .tokenPrice
                                                                .symbol
                                                        }
                                                    </h4>
                                                </>
                                            )}
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item md={6} lg={6} sm={12} xs={12}>
                                <Button
                                    disabled={walletInfo.coinBalance === 0}
                                    onClick={() => {
                                        if (
                                            parseFloat(walletInfo.coinBalance) -
                                                transacationFeesCoins >
                                            transacationFeesCoins
                                        ) {
                                            setOpen(true);
                                            setMode('Buy');

                                            let tokens =
                                                totalUSD /
                                                parseFloat(oneTokenPriceInUSD);
                                            setTransferCoins(bal);
                                            setTransferAmount(totalUSD);
                                            setTransferTokens(parseInt(tokens));
                                        } else {
                                            Swal.fire({
                                                icon: 'error',
                                                title: 'Insufficient Funds',
                                                text:
                                                    'Atleast ' +
                                                    transacationFeesCoins * 2 +
                                                    ' BNB required to buy tokens'
                                            });
                                        }
                                    }}
                                    color="primary"
                                    className="my-2"
                                    fullWidth
                                    variant="contained"
                                >
                                    Buy Tokens
                                </Button>
                                <Button
                                    disabled={walletInfo.coinBalance === 0}
                                    onClick={() => {
                                        if (
                                            parseFloat(
                                                walletInfo.tokenBalance
                                            ) -
                                                transacationFeesToken >
                                            transacationFeesToken
                                        ) {
                                            setOpen(true);
                                            setMode('Sell');
                                            let bal =
                                                parseFloat(
                                                    walletInfo.tokenBalance
                                                ) - transacationFeesToken;
                                            let totalUSD =
                                                parseFloat(bal) *
                                                oneTokenPriceInUSD;
                                            let coins =
                                                totalUSD /
                                                parseFloat(oneBNBPriceInUSD);
                                            setTransferCoins(coins);
                                            setTransferTokens(parseInt(bal));
                                        } else {
                                            Swal.fire({
                                                icon: 'error',
                                                title: 'Insufficient Funds',
                                                text:
                                                    'Atleast ' +
                                                    transacationFeesToken * 2 +
                                                    ' Tokens required to sell tokens'
                                            });
                                        }
                                    }}
                                    color="secondary"
                                    fullWidth
                                    variant="contained"
                                    className="my-2"
                                >
                                    Sell Tokens
                                </Button>
                                <Button
                                    disabled={walletInfo.coinBalance === 0}
                                    onClick={() => {
                                        if (!currentUser.withdrawAddress) {
                                            Swal.fire({
                                                title: 'Withdraw Address',
                                                text: 'Please add withdrawal address to proceed',
                                                icon: 'warning',
                                                confirmButtonText: 'Close'
                                            });
                                        } else {
                                            setOpen(true);
                                            setMode('Withdraw');
                                            setWithdrawMode('USDT');
                                            setTransferTokens(
                                                parseFloat(
                                                    walletInfo.usdtTokenBalance
                                                ) - 1
                                            );
                                        }
                                    }}
                                    color="primary"
                                    fullWidth
                                    variant="outlined"
                                    className="my-2"
                                >
                                    Withdraw
                                </Button>
                                <Button
                                    color="secondary"
                                    onClick={() => {
                                        setMode('Deposit');
                                        setOpen(true);
                                    }}
                                    fullWidth
                                    variant="outlined"
                                    className="my-2"
                                >
                                    BNB / USDT Deposit
                                </Button>
                                <List>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar className="bg-primary">
                                                <AccountBalanceWallet />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primaryTypographyProps={{
                                                className: 'overflow-text'
                                            }}
                                            onClick={() => {
                                                navigator.clipboard.writeText(
                                                    currentUser.withdrawAddress
                                                );
                                                Toastr.info(
                                                    'Address copied to clipboard'
                                                );
                                            }}
                                            primary={
                                                currentUser.withdrawAddress
                                            }
                                            secondary="Withdraw Wallet"
                                        />
                                        {!currentUser.withdrawAddress && (
                                            <ListItemSecondaryAction>
                                                <Button
                                                    onClick={() => {
                                                        setMode('Whitelist');
                                                        setOpen(true);
                                                    }}
                                                    color="secondary"
                                                    variant="contained"
                                                    size="small"
                                                >
                                                    Add Wallet
                                                </Button>
                                            </ListItemSecondaryAction>
                                        )}
                                    </ListItem>
                                </List>
                            </Grid>
                        </Grid>
                    ) : value === 1 ? (
                        <Grid container spacing={2}>
                            <Grid item md={6} lg={6} sm={12} xs={12}>
                                <List>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <AccountBoxSharp />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={
                                                currentUser.firstName +
                                                ' ' +
                                                currentUser.lastName
                                            }
                                            secondary="Name"
                                        />
                                    </ListItem>
                                    <Divider />

                                    <ListItem>
                                        <ListItemAvatar>
                                            <Fingerprint />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={currentUser.memberId}
                                            secondary="Member #"
                                        />
                                    </ListItem>
                                    <Divider />
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Email />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={currentUser.email}
                                            secondary="Email"
                                        />
                                    </ListItem>
                                    <Divider />
                                    <ListItem>
                                        <ListItemAvatar>
                                            <PhoneAndroid />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={currentUser.mobileNumber}
                                            secondary="Mobile Number"
                                        />
                                    </ListItem>
                                    <Divider />
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Link />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={
                                                <>
                                                    {document.location.href.replace(
                                                        'profile',
                                                        'signup'
                                                    ) +
                                                        '?ref=' +
                                                        currentUser.memberId}
                                                    &nbsp;
                                                    <FileCopy
                                                        color="secondary"
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(
                                                                document.location.href.replace(
                                                                    'profile',
                                                                    'signup'
                                                                ) +
                                                                    '?ref=' +
                                                                    currentUser.memberId
                                                            );
                                                            Toastr.info(
                                                                'Referral Link copied to clipboard'
                                                            );
                                                        }}
                                                    />{' '}
                                                </>
                                            }
                                            secondary="Referral Link"
                                        />
                                    </ListItem>
                                    <Divider />
                                    <ListItem>
                                        <ListItemAvatar>
                                            <PersonAdd />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={currentUser.referal}
                                            secondary="Referred By"
                                        />
                                    </ListItem>
                                    <Divider />
                                    <ListItem>
                                        <ListItemAvatar>
                                            <CalendarToday />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={moment(
                                                currentUser.createdAt
                                            ).format('LLL')}
                                            secondary="Joined On"
                                        />
                                        <ListItemSecondaryAction>
                                            {moment(
                                                currentUser.createdAt
                                            ).fromNow()}
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                </List>
                            </Grid>
                            <Grid item md={6} lg={6} sm={12} xs={12}>
                                <h4 className="my-2">Nominee Details</h4>
                                <Divider />
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    disabled={currentUser.nomineeName}
                                    placeholder="Enter Nominee Name"
                                    margin="dense"
                                    onChange={(e) =>
                                        setNomineeName(e.target.value)
                                    }
                                    value={nomineeName}
                                />
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    disabled={currentUser.nomineeName}
                                    placeholder="Enter Nominee DOB"
                                    margin="dense"
                                    type="date"
                                    onChange={(e) =>
                                        setNomineeDOB(e.target.value)
                                    }
                                    value={nomineeDOB}
                                />
                                <FormControl
                                    fullWidth
                                    variant="outlined"
                                    margin="dense"
                                >
                                    <Select
                                        value={nomineeRelation}
                                        onChange={(e) =>
                                            setNomineeRelation(e.target.value)
                                        }
                                        disabled={currentUser.nomineeName}
                                        displayEmpty
                                    >
                                        <MenuItem value="">
                                            Select Relationship
                                        </MenuItem>
                                        <MenuItem value={'Father'}>
                                            Father
                                        </MenuItem>
                                        <MenuItem value={'Mother'}>
                                            Mother
                                        </MenuItem>
                                        <MenuItem value={'Spouse'}>
                                            Spouse
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                                {!currentUser.nomineeName && (
                                    <Button
                                        onClick={() => onUpdateNominee()}
                                        className="mt-2"
                                        variant="contained"
                                        fullWidth
                                        color="primary"
                                    >
                                        Update Nominee
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container spacing={2}>
                            <Grid item md={12} lg={12} sm={12} xs={12}>
                                <Container maxWidth="xs">
                                    <List>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar className="bg-primary">
                                                    <Lock />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={
                                                    currentUser.pinType ===
                                                    'PIN'
                                                        ? 'Using Pin'
                                                        : 'Google Authenticator'
                                                }
                                                secondary="Two Factor Authentication"
                                            />
                                            <ListItemSecondaryAction>
                                                <Button
                                                    variant="outlined"
                                                    size="small"
                                                    color="secondary"
                                                    onClick={() => {
                                                        if (
                                                            currentUser.pinType ===
                                                            'PIN'
                                                        ) {
                                                            UserAPI.Get2FASetUpCode().then(
                                                                (s) => {
                                                                    SetQRCode(
                                                                        s.url
                                                                    );
                                                                    SetQRCodePin(
                                                                        s.secret
                                                                    );
                                                                    set2FA(
                                                                        true
                                                                    );
                                                                }
                                                            );
                                                        } else {
                                                            set2FA(true);
                                                        }
                                                    }}
                                                >
                                                    Change
                                                </Button>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </List>
                                    <Button
                                        variant="outlined"
                                        className="m-1"
                                        fullWidth
                                        color="primary"
                                        onClick={() => setChange(true)}
                                    >
                                        Change Password
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        className="m-1"
                                        fullWidth
                                        color="secondary"
                                        onClick={() => {
                                            UserAPI.Get2FASetUpCode().then(
                                                (s) => {
                                                    SetQRCode(s.url);
                                                    SetQRCodePin(s.secret);
                                                    setForgot2FA(true);
                                                }
                                            );
                                        }}
                                    >
                                        Forgot 2FA
                                    </Button>
                                </Container>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </div>
    );
}
